import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import SidebarRight from "../../../component/Sideber/SidebarRight";
import Tournament_DB_Details from "../../../component/Tounamet_dashboard_Details/Tournament_DB_Details";

import { useSelector, useDispatch } from "react-redux";
import {
  GetAllOneShot,
  DeleteOneShotAction,
} from "../../../Store/Action/oneShotAction";
import {
  GetAllTournament,
  Delete_Tournament,
  Update_Tournament,
} from "../../../Store/Action/tournamentAction";
import { GetAllDashBoardData } from "../../../Store/Action/OrgDashBoardAction";

import {
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
} from "../../../Store/Types/oneShotTypes";
import {
  TOURNAMENT_SUCCESS_CLEAR,
  TOURNAMENT_ERROR_CLEAR,
} from "../../../Store/Types/tournamentTypes";

import Swal from "sweetalert2";
import { notify } from "../../../component/ToastMsg/ToastMsg";

import { MagnifyingGlass } from "react-loader-spinner";

import { leaveAllRoom } from "../../../HelperFunctions/Socket/socket";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import {
  OverallCompetationStatistics,
  listOfTheCandidatePresent,
  listOfWordsUsedCSV,
  qualifiedCondidateCSV,
} from "../../../Store/Action/roundAction";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const translationMap = {
  Surname: "Nom de famille",
  "First Names": "Prénoms",
  Gender: "Genre",
  Age: "Âge",
  level: "niveau",
  Establishment: "Établissement",
  Department: "Département",
  "Number of Words Spelled": "Nombre de mots épelés",
  "Number of Correct Spellings": "Nombre de mots correctement épelés",
  "Average Spelling Time (sec)": "Temps moyen d'épellation (sec)",
  "Total Spelling Time (sec)": "Temps total d'épellation (sec)",
  "Student Number": "Numéro d'étudiant",
  "First Names": "Prénoms",
  Round: "Tour",
  "Given Spelling": "Orthographe donnée",
  "Correct Spelling": "Orthographe correcte",
  "Duration of Spelling (sec)": "Durée de l'orthographe (sec)",
  "Sanction for Spelling": "Sanction pour l'orthographe",
  "Rank Occupied": "Rang occupé",
  "Candidate Number": "Numéro de candidat",
  Spelling: "Orthographe",
  "Alternative Spelling": "Orthographe alternative",
  "Used in Round Number": "Utilisé au tour numéro",
  "Number of the Candidate Who Spelled It": "Numéro du candidat qui l'a épelé",
  "Spelling Given by the Said Candidate":
    "Orthographe donnée par le dit candidat",
  "Round Number": "Numéro de tour",
  "Number of Candidates at the Beginning": "Nombre de candidats au début",
  "Number of Candidates at the End": "Nombre de candidats à la fin",
  "Number of Words Used": "Nombre de mots utilisés",
  "Number of Correct Spellings": "Nombre de mots correctement épelés",
  "Duration of the Competition Per Round (min)":
    "Durée de la compétition par tour (min)",
  "Total Duration (min)": "Durée totale (min)",
  "Average Duration of Spelling Per Candidate (sec)":
    "Durée moyenne de l'orthographe par candidat (sec)",
    "Word Number" : "Numéro du mot"
};
function translateObjectKeys(obj, translationMap) {
  const translatedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    const translatedKey = translationMap[key] || key;
    translatedObj[translatedKey] = value; // Map to translated key
  }
  return translatedObj;
}

const OrgDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, currentUserInfo } = useSelector((state) => state.Auth);
  const {
    CSVarray,
    OverallCompetitionStatistics,
    listOfWords,
    download_Success,
    listOfCandidatePresent,
  } = useSelector((state) => state.Round);

  const {
    OneShot_Loading,
    OneShot_Data,
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
  } = useSelector((state) => state.OneShot);
  const {
    tournament_Loading,
    tournament_Data,
    tournament_SuccessMessage,
    tournament_ErrorMessage,
  } = useSelector((state) => state.Tournament);
  const {
    Org_Db_Loading,
    Org_Db_Data,
    Org_Db_SuccessMessage,
    Org_Db_ErrorMessage,
  } = useSelector((state) => state.OrgDashboard);

  const [showTournamentDetails, setShowTournamentdetails] = useState(null);
  const [language, setLanguage] = useState("fr");

  const handleShowDetails = (tournamentId) => {
    if (showTournamentDetails === tournamentId) {
      setShowTournamentdetails(null);
    } else {
      setShowTournamentdetails(tournamentId); // Show details for the clicked tournament
    }
  };

  const checkColor = (compDetails) => {
    if (
      compDetails.participants.length > compDetails.totalParticipants ||
      compDetails.participants.length === 0
    ) {
      return { backgroundColor: "#ffc107" };
    } else if (compDetails.jury.length === 0) {
      return { backgroundColor: "#ffc107" };
    } else if (
      compDetails.words &&
      compDetails.words.length < compDetails.participants.length * 2
    ) {
      // console.log(84);
      return { backgroundColor: "#ffc107" };
    } else {
      return null;
    }
  };

  const handleStart = (compDetails) => {
    if (compDetails?.participants.length === 0) {

      const translateText = MsgTranslator("You supposede to have [_a_] participants",language);
      const _a = compDetails.totalParticipants;
      const text = translateText.replace("[_a_]", _a);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      });
    } else if (
      compDetails.participants.length > compDetails.totalParticipants
    ) {
      // console.log(
      //   compDetails.participants.length,
      //   compDetails.totalParticipants
      // );

      let translatedText = MsgTranslator(
        `You added {_a_} extra participants. You add {_b_} participants but you supposede to have {_c_} participants`,
        language
      );

      const _a =
        compDetails.participants.length - compDetails.totalParticipants;
      const _b = compDetails.participants.length;
      const _c = compDetails.totalParticipants;

      const text = translatedText
        .replace("{_a_}", _a)
        .replace("{_b_}", _b)
        .replace("{_c_}", _c);

      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      });
    } else if (compDetails.jury.length === 0) {
      const text = MsgTranslator('You must have jury to start the competition.',language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      });
    } else if (
      compDetails.words &&
      compDetails.words.length < compDetails.participants.length * 2
    ) {
      const text = MsgTranslator('You must have jury to start the competition.',language)
        
      Swal.fire({
        icon: "error",
        title:  MsgTranslator("Oops...",language),
        text: text,
      });
    } else {
      navigate(`/start-competition/${compDetails._id}`);
    }
  };

  /* Getting Org dashboard all data */
  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetAllDashBoardData({ orgId: currentUserInfo._id }));
    leaveAllRoom(currentUserInfo._id);
  }, [currentUserInfo._id]);

  /* delete One shot */
  const DeleteOneShot = (oneShotId) => {
    dispatch(
      DeleteOneShotAction({ compId: oneShotId, orgId: currentUserInfo._id })
    ).then(() => {
      dispatch({ type: "RESET_REDUX" });
      dispatch(GetAllDashBoardData({ orgId: currentUserInfo._id }));
    });
  };

  /* Delete Tournament */
  const DeleteTournament = (tournamentId) => {
    dispatch(
      Delete_Tournament({
        tournamentId: tournamentId,
        orgId: currentUserInfo._id,
      })
    ).then(() => {
      dispatch({ type: "RESET_REDUX" });
      dispatch(GetAllDashBoardData({ orgId: currentUserInfo._id }));
    });
  };
  /* tournament end */
  const handleEndTournament = (tournamentId) => {
    dispatch(
      Update_Tournament({
        orgId: currentUserInfo._id,
        tournamentId: tournamentId,
        isCompleted: true,
      })
    ).then(() => {
      dispatch({ type: "RESET_REDUX" });
      dispatch(GetAllDashBoardData({ orgId: currentUserInfo._id }));
    });
  };

  /* ==========================|| DOWNLOAD CSV FILE ||============================= */

  const qualifiedCandidateCSV = (data) => {
    /* for translation -- in french */
    if (currentUser.language === "fr") {
      data = data.map((sd) => translateObjectKeys(sd, translationMap));
    }
    /*--------------------------------- */
    const translatedStudentNumber =
      currentUser.language === "fr"
        ? translationMap["Student Number"] || "Student Number"
        : "Student Number";
    const translatedTotalSpellingTime =
      currentUser.language === "fr"
        ? translationMap["Total Spelling Time (sec)"] ||
          "Total Spelling Time (sec)"
        : "Total Spelling Time (sec)";
    // console.log(translatedStudentNumber);
    // console.log(translationMap["Student Number"]);
    const csvData = data.map((row, index) => {
      return {
        ...row,
        [translatedStudentNumber]: index + 1,
      };
    });

    // Getting headers from the first row
    const headers = Object.keys(csvData[0]);

    // Reordering headers so that Student Number comes first and Total Spelling Time (sec) comes last
    const reorderedHeaders = [
      translatedStudentNumber,
      ...headers.filter(
        (header) =>
          header !== translatedStudentNumber &&
          header !== translatedTotalSpellingTime
      ),
      translatedTotalSpellingTime,
    ];

    // Generating CSV string
    let csv = "\uFEFF" + reorderedHeaders.join(",") + "\n";
    csv += csvData
      .map((row) =>
        reorderedHeaders
          .map((fieldName) =>
            row[fieldName] !== undefined ? row[fieldName] : ""
          )
          .join(",")
      )
      .join("\n");
    return csv;
  };

  const OverAllCompetationObjects = (data) => {
    if (currentUser.language === "fr") {
      data = data.map((sd) => translateObjectKeys(sd, translationMap));
    }

    const translatedRoundNumber =
      currentUser.language === "fr"
        ? translationMap["Round Number"] || "Round Number"
        : "Round Number";

    const csvData = data.map((row, index) => {
      return {
        [translatedRoundNumber]: index + 1,
        ...row,
      };
    });

    // Getting headers from the first row
    const headers = Object.keys(csvData[0]);

    // Convert data to CSV format
    let csv = "\uFEFF" + headers.join(",") + "\n";;
    csv += csvData
      .map((row) =>
        headers
          .map((header) => (row[header] !== undefined ? row[header] : ""))
          .join(",")
      )
      .join("\n");

    return csv;
  };

  const listOfWordsUsedObjects = (data) => {
    if (currentUser.language === "fr") {
      data = data.map((sd) => translateObjectKeys(sd, translationMap));
    }

    const translatedWordNumber =
      currentUser.language === "fr"
        ? translationMap["Word Number"] || "Word Number"
        : "Word Number";

    const csvData = data.map((row, index) => {
      return {
        [translatedWordNumber]: index + 1,
        ...row,
      };
    });

    // Getting headers from the first row
    const headers = Object.keys(csvData[0]);

    // Convert data to CSV format
    let csv = "\uFEFF" + headers.join(",") + "\n";
    csv += csvData
      .map((row) =>
        headers
          .map((header) => (row[header] !== undefined ? row[header] : ""))
          .join(",")
      )
      .join("\n");

    return csv;
  };

  // const [compId, setCompId] = useState();
  // useEffect(() => {
  //   if (competationId && Object.keys(competationId).length > 0) {
  //     const { compId } = competationId;
  //     setCompId(compId);
  //   }
  // }, [competationId]);

  const listOfcandidates = (data) => {
    if (currentUser.language === "fr") {
      data = data.map((sd) => translateObjectKeys(sd, translationMap));
    }

    const translatedCandidateNumber =
      currentUser.language === "fr"
        ? translationMap["Candidate Number"] || "Candidate Number"
        : "Candidate Number";

    const csvData = data.map((row, index) => {
      const newRow = {
        [translatedCandidateNumber]: row.CandidateNumber,
        ...row,
      };

      delete newRow.CandidateNumber;

      return newRow;
    });

    // Getting headers from the first row
    const headers = Object.keys(csvData[0]);

    // Convert data to CSV format
    let csv = "\uFEFF" + headers.join(",") + "\n";;
    csv += csvData
      .map((row) =>
        headers
          .map((header) => (row[header] !== undefined ? row[header] : ""))
          .join(",")
      )
      .join("\n");

    return csv;
  };

  const qualifiedCondidate = (id) => {
    dispatch(qualifiedCondidateCSV(id));
  };

  const handleOverAllCompStattics = (id) => {
    dispatch(OverallCompetationStatistics(id));
  };

  const listOfWordsUsed = (id) => {
    dispatch(listOfWordsUsedCSV(id));
  };
  const listOfTheCandidatePresents = (id) => {
    dispatch(listOfTheCandidatePresent(id));
  };
  /* =================================================================================== */

  useEffect(() => {
    if (CSVarray.length) {
      // console.log("first");
      const csvData = CSVarray.map((row, index) => {
        const newRow = { ...row };
        delete newRow._id; // Remove _id field
        return newRow;
      });

      const csv = qualifiedCandidateCSV(csvData);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "quelified-condidate.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch({ type: "ROUND_DLD_CSV_CLR_MSG" });
    }

    if (OverallCompetitionStatistics.length) {
      const csv = OverAllCompetationObjects(OverallCompetitionStatistics);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "competationStatics.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: "ROUND_DLD_CSV_CLR_MSG" });
    }

    if (listOfWords.length) {
      const csv = listOfWordsUsedObjects(listOfWords);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ListOfWordsUsed.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: "ROUND_DLD_CSV_CLR_MSG" });
    }

    if (listOfCandidatePresent.length) {
      const csv = listOfcandidates(listOfCandidatePresent);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ListOfCandidatePresent.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: "ROUND_DLD_CSV_CLR_MSG" });
    }
  }, [download_Success]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page:  MsgTranslator("Dashboard",language),
                   
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
              ]}
            />
            <div className="upcoming-title">
              <h3>
                <span>
                  {/* <TranslatedComponent>
                    <em></em>{" "}
                  </TranslatedComponent>{" "}
                  <TranslatedComponent></TranslatedComponent> */}
                  <PageTitle
                    title={"<em>Upcoming</em> Competitions"}
                    lang={language}
                  />
                </span>
                {/* <span>
                  <em>Upcoming</em> Competitions
                </span> */}
              </h3>
            </div>
            <div className="upcoming-box scrollable">
              <div>
                <table className="table gfg ">
                  <thead>
                    <TranslatedComponent>
                      <tr className="green-line-3">
                        <th></th>
                        <th>
                          <span className="icon-name-st">Competition Name</span>
                        </th>
                        <th>
                          <span className="icon-name-type">Type</span>
                        </th>
                        <th>
                          <span className="icon-name-date">Date</span>
                        </th>
                        <th>
                          <span className="icon-name-time">Time</span>
                        </th>
                      </tr>
                    </TranslatedComponent>
                  </thead>
                  <tbody>
                    {!Org_Db_Loading ? (
                      Org_Db_Data &&
                      Org_Db_Data.upcomingCompetition &&
                      Org_Db_Data.upcomingCompetition.length > 0 ? (
                        Org_Db_Data.upcomingCompetition.map((sos, index) => {
                          return (
                            <>
                              <TranslatedComponent>
                                <tr className="geeks" key={sos._id}>
                                  <td>{index + 1}</td>
                                  <td
                                    onClick={() => {
                                      if (sos.tournamentRef) {
                                        navigate(
                                          `/tournament-details/${sos._id}`
                                        );
                                      } else {
                                        navigate(
                                          `/one-shot-details/${sos._id}`
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {sos?.name || ""}
                                  </td>

                                  <td>
                                    {sos.tournamentRef && (
                                      <button
                                        onClick={() =>
                                          handleShowDetails(sos._id)
                                        }
                                        className="tournament-8"
                                      >
                                        <span className="dropbtn ">
                                          <img
                                            src={
                                              window.location.origin +
                                              "/Assets/images/dropdown-arrow.png"
                                            }
                                            alt="Dropdown Arrow"
                                          />
                                        </span>
                                      </button>
                                    )}
                                    {sos.tournamentRef
                                      ? "Tournament"
                                      : "One Shot"}
                                  </td>
                                  <td>{sos?.date || ""}</td>
                                  <td>{sos.time}</td>
                                  <td className="dropdown">
                                    <button
                                      className="dropbtn dropdown-toggle tree-dute"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img
                                        src={
                                          window.location.origin +
                                          "/Assets/images/point-arrow.png"
                                        }
                                        alt="Point Arrow"
                                      />
                                    </button>
                                    {!sos?.tournamentRef ? (
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate(
                                              `/all-participant/${sos._id}`
                                            )
                                          }
                                        >
                                          Paricipants
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate(
                                              `/organiser-word-list/${sos._id}`
                                            )
                                          }
                                        >
                                          Wordlist
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate(`/all-jury/${sos._id}`)
                                          }
                                        >
                                          Jury Members
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => DeleteOneShot(sos._id)}
                                        >
                                          Delete
                                        </a>
                                      </div>
                                    ) : (
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate(
                                              `/all-participant/${sos.tournamentRef}`
                                            )
                                          }
                                        >
                                          Paricipants
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleEndTournament(
                                              sos.tournamentRef
                                            )
                                          }
                                        >
                                          End Tournament
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            DeleteTournament(sos.tournamentRef)
                                          }
                                        >
                                          Delete
                                        </a>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <button className="shear-one">Share</button>
                                  </td>
                                  <td>
                                    {/* conditional button */}
                                    {sos.tournamentRef ? (
                                      <button
                                        className="resume"
                                        onClick={() =>
                                          navigate(
                                            `/phase-manage/${sos.tournamentRef}`
                                          )
                                        }
                                      >
                                        Manage
                                      </button>
                                    ) : sos.isStarted === true ? (
                                      <button
                                        className="resume"
                                        onClick={() =>
                                          navigate(
                                            `/start-competition-round/${sos._id}`
                                          )
                                        }
                                      >
                                        Resume
                                      </button>
                                    ) : (
                                      <button
                                        className="resume"
                                        onClick={() => handleStart(sos)}
                                        style={checkColor(sos)}
                                      >
                                        Start
                                      </button>
                                    )}
                                    {/* <button className="resume">Start</button> */}
                                  </td>
                                </tr>
                              </TranslatedComponent>

                              {/* {console.log(
                                showTournamentDetails,
                                sos.tournamentRef,
                                showTournamentDetails === sos.tournamentRef
                              )} */}
                              {showTournamentDetails &&
                                showTournamentDetails === sos.tournamentRef && (
                                  <tr className="geeks">
                                    <td
                                      colSpan={8}
                                      style={{ border: "0", padding: "0" }}
                                    >
                                      <Tournament_DB_Details
                                        tournamentId={showTournamentDetails}
                                      />
                                      {/* <Tournament_DB_Details
                                        tournamentId={showTournamentDetails}
                                        show={
                                          showTournamentDetails ===
                                          sos.tournamentRef
                                        }
                                      /> */}
                                    </td>
                                  </tr>
                                )}
                            </>
                          );
                        })
                      ) : null
                    ) : (
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan="6">
                          <MagnifyingGlass
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="magnifying-glass-loading"
                            wrapperStyle={{ display: "inline-block" }}
                            wrapperClass="magnifying-glass-wrapper"
                            glassColor="#c0efff"
                            color="#e15b64"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="upcoming-title">
              <h3>
                {/* <span> */}
                {/* <TranslatedComponent>
                    <em></em>
                  </TranslatedComponent>{" "}
                  <TranslatedComponent></TranslatedComponent> */}
                {/* </span> */}
                <PageTitle
                  title={"<em>Past</em> Competitions"}
                  lang={language}
                />
              </h3>
            </div>

            <div className="upcoming-box scrollable past-competitions">
              <div>
                <TranslatedComponent>
                  <table className="table gfg">
                    <thead>
                      <tr className="green-line-3">
                        <th></th>
                        <th>
                          <span className="icon-name-st">Competition Name</span>
                        </th>
                        <th>
                          <span className="icon-name-type">Type</span>
                        </th>
                        <th>
                          <span className="icon-name-date">Start Date</span>
                        </th>
                        <th>
                          <span className="icon-name-date">End date</span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {!Org_Db_Loading ? (
                        Org_Db_Data &&
                        Org_Db_Data.pastCompetition &&
                        Org_Db_Data.pastCompetition.length > 0 ? (
                          Org_Db_Data.pastCompetition.map((sos, index) => {
                            return (
                              <>
                                <tr className="geeks" key={sos._id}>
                                  <td>{index + 1}</td>
                                  <td
                                    onClick={() => {
                                      if (sos.tournamentRef) {
                                        navigate(
                                          `/tournament-details/${sos.tournamentRef}`
                                        );
                                      } else {
                                        navigate(
                                          `/one-shot-details/${sos._id}`
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {sos?.name || ""}
                                  </td>

                                  <td>
                                    {sos.tournamentRef && (
                                      <button
                                        onClick={() =>
                                          handleShowDetails(sos.tournamentRef)
                                        }
                                        className="tournament-8"
                                      >
                                        <span className="dropbtn ">
                                          <img
                                            src={
                                              window.location.origin +
                                              "/Assets/images/dropdown-arrow.png"
                                            }
                                            alt="Dropdown Arrow"
                                          />
                                        </span>
                                      </button>
                                    )}
                                    {sos.tournamentRef
                                      ? "Tournament"
                                      : "One Shot"}
                                  </td>
                                  <td>{sos?.date || ""}</td>
                                  <td>{sos.time}</td>
                                  <td className="dropdown">
                                    <button
                                      className="dropbtn dropdown-toggle tree-dute"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img
                                        src={
                                          window.location.origin +
                                          "/Assets/images/point-arrow.png"
                                        }
                                        alt="Point Arrow"
                                      />
                                    </button>
                                    {!sos?.tournamentRef ? (
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        {/* <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          navigate(
                                            `/all-participant/${sos._id}`
                                          )
                                        }
                                      >
                                        Paricipants
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          navigate(
                                            `/organiser-word-list/${sos._id}`
                                          )
                                        }
                                      >
                                        Wordlist
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          navigate(`/all-jury/${sos._id}`)
                                        }
                                      >
                                        Jury Members
                                      </a>
                                      */}
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            navigate(
                                              `/competition-results/${sos._id}`
                                            )
                                          }
                                        >
                                          Result
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => {
                                            qualifiedCondidate(sos._id);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          Qualified Condidate
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => {
                                            handleOverAllCompStattics(sos._id);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          Over All Competation Statics
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => {
                                            listOfWordsUsed(sos._id);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          List Of Words Used
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => {
                                            listOfTheCandidatePresents(sos._id);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          List Of Candidate Present
                                        </a>

                                        <a
                                          className="dropdown-item"
                                          style={{ color: "red" }}
                                          onClick={() => DeleteOneShot(sos._id)}
                                        >
                                          Delete
                                        </a>
                                      </div>
                                    ) : (
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            navigate(
                                              `/phase-manage/${sos.tournamentRef}`
                                            )
                                          }
                                        >
                                          Result
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          style={{ color: "red" }}
                                          onClick={() =>
                                            DeleteTournament(sos.tournamentRef)
                                          }
                                        >
                                          Delete
                                        </a>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <button className="shear-one">Share</button>
                                  </td>
                                </tr>
                                {/* {console.log(
                                showTournamentDetails,
                                sos.tournamentRef,
                                showTournamentDetails === sos.tournamentRef
                              )} */}
                                {showTournamentDetails &&
                                  showTournamentDetails ===
                                    sos.tournamentRef && (
                                    <tr className="geeks">
                                      <td
                                        colSpan={8}
                                        style={{ border: "0", padding: "0" }}
                                      >
                                        <Tournament_DB_Details
                                          tournamentId={showTournamentDetails}
                                        />
                                        {/* <Tournament_DB_Details
                                        tournamentId={showTournamentDetails}
                                        show={
                                          showTournamentDetails ===
                                          sos.tournamentRef
                                        }
                                      /> */}
                                      </td>
                                    </tr>
                                  )}
                              </>
                            );
                          })
                        ) : null
                      ) : (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="6">
                            <MagnifyingGlass
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="magnifying-glass-loading"
                              wrapperStyle={{ display: "inline-block" }}
                              wrapperClass="magnifying-glass-wrapper"
                              glassColor="#c0efff"
                              color="#e15b64"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </TranslatedComponent>
              </div>
            </div>
          </div>
        </div>

        <SidebarRight />
      </div>
    </>
  );
};

export default OrgDashboard;
