import io from "socket.io-client";
import { SOCKET_URL } from "../../Config/Config";
// import { notify } from "../../component/ToastMsg/ToastMsg";
const socket = io(`${SOCKET_URL}`);
// const socket = io("https://epelle-socket.onrender.com");
// const socket = io("http://localhost:5000");

export const StartSocketConnection = () => {
  socket.on("connect", () => {
    console.log("Connected to socket server");
  });
};

export const JoinRoom = (comp_id, userId) => {
  socket.emit("joinCompetition", {
    competitionId: comp_id,
    userId: userId,
  });
};

/*************************************************************** */
export const curentTimeChange = (compId, time) => {
  socket.emit("currentTime", {
    competitionId: compId,
    time: time,
  });
};

export const getCurrentTime = (callback) => {
  socket.on("currentTime", ({ competitionId, time }) => {
    callback({ competitionId, time });
  });
};

/***********************************************************/
export const timerStateChange = (compId, timer) => {
  // console.log(compId, timer);
  socket.emit("timerState", {
    competitionId: compId,
    timer: timer,
  });
};

export const getTimerState = (callback) => {
  socket.on("timerState", ({ competitionId, timer }) => {
    // console.log("now timer state--->" + timer);
    callback({ competitionId, timer });
  });
};
/*************************************************************/
export const btnStateChange = (compId, btnState) => {
  socket.emit("btnState", {
    competitionId: compId,
    btnState: btnState,
  });
};

export const getBtnState = (callback) => {
  socket.on("btnState", ({ competitionId, btnState }) => {
    callback({ competitionId, btnState });
  });
};
/*************************************************************/
// export const timerStartStateChange = (compId, timerStartState) => {
//   socket.emit("timerStartState", {
//     competitionId: compId,
//     timerStartState: timerStartState,
//   });
// };

// export const getTimerStartState = (callback) => {
//   socket.on("timerStartState", ({ competitionId, timerStartState }) => {
//     callback({ competitionId, timerStartState });
//   });
// };

/*************************************************************/
export const curentTextChange = (compId, text) => {
  socket.emit("currentText", {
    competitionId: compId,
    text: text,
  });
};

export const getCurrentText = (callback) => {
  socket.on("currentText", ({ competitionId, text }) => {
    callback({ competitionId, text });
  });
};

/********************************************************** */

export const pageChange = (compId, url) => {
  socket.emit("pageChange", {
    competitionId: compId,
    page: url,
  });
};

export const pageQuery = (callback) => {
  socket.on("pageChange", ({ competitionId, page }) => {
    // console.log(`Page changed to ${page}`);
    callback({ competitionId, page });
  });
};

export const BackToOrigin = (compId, url) => {
  socket.emit("BackToOrigin", {
    competitionId: compId,
    page: url,
  });
};

export const FinishedQuery = (callback) => {
  socket.on("BackToOrigin", ({ competitionId, page }) => {
    // console.log(`Page changed to ${page}`);
    callback({ competitionId, page });
  });
};
/*************************************************************/
export const wordDetailsChange = (compId, wordDetailsState) => {
  socket.emit("wordDetailsState", {
    competitionId: compId,
    wordDetailsState: wordDetailsState,
  });
};

export const getWordDetailsChange = (callback) => {
  socket.on("wordDetailsState", ({ competitionId, wordDetailsState }) => {
    callback({ competitionId, wordDetailsState });
  });
};
/*************************************************************/
// export const EndSocketConnection = () => {
//   socket.emit("leaveRoom", (msg) => {
//     notify("Connection closed", "success");
//     console.log(msg);
//   });
// };

export const leaveAllRoom = (userId) => {
  socket.emit("leaveAllRoom", userId);
};

export const leaveRoom = (userId, roomName) => {
  socket.emit("leaveRoom", { userId, roomName });
};
