import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../../component/Sideber/SidebarLeft";
import Header from "../../../../component/Header/Header";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { useSelector, useDispatch } from "react-redux";
import { GetTournamentDetails } from "../../../../Store/Action/tournamentAction";
import {
  TOURNAMENT_SUCCESS_CLEAR,
  TOURNAMENT_ERROR_CLEAR,
} from "../../../../Store/Types/tournamentTypes";
import Swal from "sweetalert2";
import FileUploader from "../../../../component/FileUploader/FileUploader";

import ComponentLoader from "../../../../component/ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../../../translator/TranslatedComponent";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../../HelperFunctions/MsgTranslator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TournamentDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const {
    tournament_Loading,
    tournamentDetails,
    tournament_SuccessMessage,
    tournament_ErrorMessage,
    tournament_Get_Error_Message,
  } = useSelector((state) => state.Tournament);

  const [state, setState] = useState({});
  const [language,setLanguage] = useState('fr');

  useEffect(() => {
    dispatch(
      GetTournamentDetails({ orgId: currentUserInfo._id, tournamentId: id })
    );
  }, []);

  useEffect(() => {
    if (tournamentDetails && Object.keys(tournamentDetails).length > 0) {
      setState(tournamentDetails);
    }
  }, [tournamentDetails]);

  useEffect(() => {
    if (tournament_Get_Error_Message) {
      const text = MsgTranslator(tournament_Get_Error_Message,language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: TOURNAMENT_ERROR_CLEAR });
      });
    }
  }, [tournament_Get_Error_Message]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() =>{
    if(currentUser && Object.keys(currentUser).length > 0){
      const {language} = currentUser;
      setLanguage(language);
    }
  },[currentUser]);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TranslatedComponent>
              <Box
                sx={{ border: 4, m: 2, p: 2 }}
                onClick={() => {
                  navigate(`/manual-add-participant/${id}`);
                }}
              >
                Manual Add{" "}
                <i
                  className="fa -regular fa-arrow-right"
                  style={{ color: "#000000" }}
                ></i>
              </Box>
              <Box
                sx={{ border: 4, m: 2, p: 2 }}
                onClick={() => {
                  navigate(`/quick-mode-add-participant/${id}`);
                }}
              >
                Quick Add{" "}
                <i
                  className="fa -regular fa-arrow-right"
                  style={{ color: "#000000" }}
                ></i>
              </Box>
              <Box
                sx={{ border: 4, m: 2, p: 2 }}
                onClick={() => {
                  navigate(`/existing-participant/${id}`);
                }}
              >
                Add Existing Participants
                <i
                  className="fa -regular fa-arrow-right"
                  style={{ color: "#000000" }}
                ></i>
              </Box>
            </TranslatedComponent>
            {/* <Box sx={{ border: 4, m: 2, p: 2 }} onClick={() => { navigate(``) }}>Import CSV or Excel <i className="fa -regular fa-arrow-right" style={{ color: "#000000" }}></i></Box> */}
            <FileUploader compId={id} />
          </Box>
        </Modal>
      </div>

      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                {
                  page: MsgTranslator("Tournament Details",language),
                  link: `/tournament-details/${id}`,
                },
              ]}
            />
            {!tournament_Loading ? (
              <>
                <div className="upcoming-title">
                  <h3>
                    <PageTitle
                      title={"<em>Tournament</em> Details"}
                      lang={language}
                    />
                  </h3>
                </div>
                <TranslatedComponent>
                  <div className="candit-sectied-from">
                    <div className="ft-create-section">
                      <div className="text-from-one">
                        <form>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  Competition Name :
                                </label>
                                <div className="col-sm-7">
                                  <p className="unser-piont">
                                    {tournamentDetails?.tournamentName || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  Competition Type :
                                </label>
                                <div className="col-sm-7">
                                  <p className="unser-piont">Tournament</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  Word Difficulty :{" "}
                                </label>
                                <div className="col-sm-7">
                                  <p className="unser-piont">
                                    {tournamentDetails?.wordDifficulty || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  From Date :
                                </label>
                                <div className="col-sm-7">
                                  <p className="unser-piont">
                                    {tournamentDetails?.startDate || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  To Date :
                                </label>
                                <div className="col-sm-7">
                                  <p className="unser-piont">
                                    {tournamentDetails?.endDate || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  Word Theme :
                                </label>
                                <div className="col-sm-7">
                                  <p className="unser-piont">
                                    {tournamentDetails?.wordTheme || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  Word Context :
                                </label>
                                <div className="col-sm-7">
                                  <p className="unser-piont">
                                    {tournamentDetails?.wordContext || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* conditional rendering */}

                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  No Of Phase :
                                </label>
                                <div
                                  className="col-sm-7"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "1rem",
                                  }}
                                >
                                  <p className="unser-piont">
                                    {tournamentDetails?.phases?.length || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail3"
                                  className="col-sm-5 col-form-label"
                                >
                                  Alloted Time :
                                </label>
                                <div className="col-sm-7">
                                  <p className="unser-piont">3:30pm</p>
                                </div>
                              </div>
                            </div>*/}
                            <div className="col-lg-4">
                              <button
                                className="edit-g"
                                onClick={() => navigate(`/phase-manage/${id}`)}
                              >
                                Manage Phases
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="buttaon-lane">
                      <div className="back-name">
                        <button className="back-g" onClick={() => navigate(-1)}>
                          Back
                        </button>
                      </div>
                      {!tournamentDetails.isCompleted && (
                        <div className="right-edit">
                          <button
                            className="edit-g"
                            onClick={() => navigate(`/edit-tournament/${id}`)}
                          >
                            Edit
                          </button>
                          <button className="start-g" onClick={handleOpen}>
                            Add Participants
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </TranslatedComponent>
              </>
            ) : (
              <ComponentLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TournamentDetails;
