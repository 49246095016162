import axios from "axios";
import React, { useEffect, useState } from "react";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import { SERVER_URL } from "../../../Config/Config";
import TranslatedComponent from "../../../translator/TranslatedComponent";

const PlayerAggrigatedDetails = (data) => {
  const [playerData, setPlayerData] = useState({});
  const [loader, setLoader] = useState(true);

  const { playerId, compId, authorizedUserId } = data;
  
  useEffect(() => {
    if (playerId && compId && authorizedUserId) {
      //api call
      axios
        .post(`${SERVER_URL}/participant/get-participant-aggrigated-details`, {
          playerId,
          compId,
          authorizedId: authorizedUserId,
        })
        .then((res) => {
          setPlayerData(res.data.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [playerId, compId, authorizedUserId]);

  return (
    <>
      {!loader && Object.keys(playerData).length >= 0 ? (
        <div className="col-xs-12 col-lg-9">
            <div className="partici-section787">
          <TranslatedComponent>
          <div className="participations-section">
            <p>
              {" "}
              Number of participations :{" "}
              <span>{playerData.overallParticipations}</span>{" "}
            </p>
            <p>
              Success rate :{" "}
              {`${playerData.wordsCorrectlySpelled}/${
                playerData.numberOfParticipations - 1
              }`}{" "}
              <span>{playerData.successRate}</span>{" "}
            </p>
            <p>
              Words correctly spelt :{" "}
              <span>{playerData.currentCompWordCorrectlySpelt}</span>{" "}
            </p>
          </div>
          </TranslatedComponent>
         
          {playerData.biography ? (
            <div className="biographie-section">{playerData.biography}</div>
          ) : null}
        </div></div>
      ) : (
        <ComponentLoader />
      )}
    </>
  );
};

export default PlayerAggrigatedDetails;
