import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GetJuryCapability } from "../../../Store/Action/juryAction";
import JuryViewRoleWise from "./JuryViewRoleWise";

import Timer from "indra-timer";
import { getCurrentTime, getWordDetailsChange, wordDetailsChange } from "../../../HelperFunctions/Socket/socket";

import CircularProgress from "@mui/material/CircularProgress";
import { AiCall } from "../../../HelperFunctions/AiCall";

import {
  EditWord,
} from "../../../Store/Action/wordAction";
import TranslatedComponent from "../../../translator/TranslatedComponent";
// import {
//   WORD_ACTION_LOADER_START,
//   WORD_SUCCESS_CLEAR,
//   WORD_ERROR_CLEAR,
// } from "../../../Store/Types/wordTypes";

const getJuryRole = (current_round_Details, currentUserInfo) => {
  if (!current_round_Details || !currentUserInfo) {
    return null;
  }

  const { jury } = current_round_Details.compId;

  // Find the jury member that matches the current user ID
  const juryMember = jury.find(j => j.juryId === currentUserInfo._id);

  // If the user is a jury member, return their role; otherwise, return null
  return juryMember ? juryMember.juryRole : null;
}

const JuryView = ({
  curPlayer,
  current_round_Details,
  Words_Details,
  handleSubmit,
  setTimeTaken,
  setSubmitting,
  Word_Loading,
  round_Loading,
  stopTimer,
  SidebarLeft,
  Header,
  CountDownTimer,
  Keyboard,
  handleInputChange,
  ComponentLoader,
  currentUserInfo,
  competitionId,
  // setPageReload
}) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(new Timer());

  const [juryRole, setjuryRole] = useState("");
  const [state, setState] = useState({});
  const [AiBtn, setAiBtn] = useState({
    definition: false,
    illustrativeSentence: false,
  });

  getWordDetailsChange(({competitionId, wordDetailsState}) => {
    console.log(68,wordDetailsState)
    setState({
      ...state,
      ...wordDetailsState,
    });
  });

  useEffect(() => {
    if (Words_Details) {
      setState({
        ...state,
        ...Words_Details,
      });
    }
  }, [Words_Details]);

  const handleAIResponse = async (fieldname) => {
    setAiBtn({ ...AiBtn, [fieldname]: true });

    const prompt = `I want to generate only the field ${fieldname} for the word ${state.word}.Try to answer simple and short.The ${fieldname} should never reference a word different from ${state.word}, not even derivatives. It should be the word itself.`;
    let AiRes = await AiCall(prompt);
    setState({ ...state, [fieldname]: AiRes });
    dispatch(EditWord({ ...state, [fieldname]: AiRes, wordId: state._id })).then(() => {
      // setPageReload(true);
      /* need socket call */
      console.log({
        wordDetailsApiCall: true,
        triggeredBy: "id",
        [fieldname]: AiRes
      });
      wordDetailsChange(current_round_Details.compId._id, { ...state, [fieldname]: AiRes });
    });
    setAiBtn({ ...AiBtn, [fieldname]: false });
  };

  const {
    Jury_Loading,
    Jury_Action_Loader,
    Jury_Capabilities,
    Jury_All_Data,
    Jury_SuccessMessage,
    Jury_ErrorMessage,
    Jury_Get_Error_Message,
  } = useSelector((state) => state.Jury);

  useEffect(() => {
    let role = getJuryRole(current_round_Details, currentUserInfo);
    setjuryRole(role);
  }, [current_round_Details, currentUserInfo])

  // /********************************* */
  const [inputHours, setInputHours] = useState(0);
  const [inputMinutes, setInputMinutes] = useState(0);
  const [inputSeconds, setInputSeconds] = useState(0);
  const formatTimeAndUpdateState = (stringTime) => {
    setInputHours(stringTime.split(":")[0]);
    setInputMinutes(stringTime.split(":")[1]);
    setInputSeconds(stringTime.split(":")[2]);
  };

  getCurrentTime(({ competitionId, time }) => {
    let currentTimeLeft = timer.formatTime(time);
    formatTimeAndUpdateState(currentTimeLeft);
  });

  // /************************************ */

  useEffect(() => {
    if (Object.keys(current_round_Details).length > 0) {
      if (currentUserInfo.role === "Jury") {
        dispatch(
          GetJuryCapability({
            juryId: currentUserInfo._id,
            compId: current_round_Details.compId._id,
          })
        );
      }
    }
  }, [current_round_Details]);

  
  return (
    <>
      {!round_Loading &&
        !Word_Loading &&
        Object.keys(current_round_Details).length > 0 &&
        Object.keys(curPlayer).length > 0 &&
        Object.keys(current_round_Details).length > 0 &&
        Object.keys(state).length > 0 ? (
        <>

          {Jury_Capabilities.includes("View Time") &&
            Object.keys(current_round_Details).length > 0 &&
            current_round_Details.compId &&
            current_round_Details.compId.allotedTime && juryRole !== "Judge" && (
              <div className="timeing-section">
                <div id="countdown" className="time-display">
                  <ul>
                    <li>
                      <span id="minute" className="numbers">
                        {inputMinutes}
                      </span>
                    </li>
                    <li>
                      <span id="second" className="numbers">
                        {inputSeconds}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          {juryRole === "Judge" && (
            <div className="timeing-section"/*style={{ display: "none" }}*/>
              <CountDownTimer
                competitionId={competitionId}
                time={current_round_Details.compId.allotedTime}
                stop={stopTimer}
                timeSpent={setTimeTaken}
                submit={setSubmitting}
              />
            </div>
          )}

          {Jury_Capabilities.includes("View Word") && (
            <JuryViewRoleWise heading={"Word"} data={state?.word} />
          )}
          {Jury_Capabilities.includes("View Word Phonetics") && (
            <JuryViewRoleWise
              heading={"Word Phonetics"}
              data={state?.word_phonetics}
            />
          )}

          {Jury_Capabilities.includes("View Other Word Details") && (
            <div className="candit-sectied-from">
              <h1 style={{ textAlign: "center" }}>
                {state?.word || "NA"}
                {state?.spellingAlternatives &&
                  state?.spellingAlternatives !== "None" &&
                  state?.spellingAlternatives !== "N/A" &&
                  state?.spellingAlternatives !== "NA" &&
                  state?.spellingAlternatives !== "-" &&
                  state?.word !== state?.spellingAlternatives
                  ? ` / ${state?.spellingAlternatives}`
                  : ""}
              </h1>
              <TranslatedComponent>
                <ul className="candidat-pt-kk">
                  <li>
                    <span>Word Phonetics :</span>{" "}
                    <p>{state?.word_phonetics || "NA"}</p>
                  </li>
                  <li>
                    <span>Origin :</span>{" "}
                    <p>{state?.origin || "NA"}</p>
                  </li>
                  <li>
                    <span>Nature :</span>{" "}
                    <p>{state?.nature || "NA"}</p>
                  </li>
                  <li>
                    <span>Homonyms :</span>{" "}
                    <p>{state?.homonyms || "NA"}</p>
                  </li>
                  <li>
                    <span>Definition :</span>{" "}
                    <p>{state?.definition || "NA"}</p>
                  </li>
                  <li>
                    <span>Illustrative Sentences :</span>{" "}
                    <p>{state?.illustrativeSentence || "NA"}</p>
                    <div className="st-edit-fild">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleAIResponse("illustrativeSentence");
                        }}
                      >
                        {AiBtn.illustrativeSentence ? (
                          <div>
                            <CircularProgress
                              style={{
                                height: "1rem",
                                width: "1rem",
                              }}
                            />
                          </div>
                        ) : (
                          <img
                            src={
                              window.location.origin +
                              `/./Assets/images/refrace.png`
                            }
                            alt="ai"
                          />
                        )}
                      </button>
                    </div>
                  </li>
                </ul>
              </TranslatedComponent>
            </div>
          )}

          {Jury_Capabilities.includes(
            "Display Virtual Keyboard" || "Enter Candidate Spelling"
          ) && (
              <div className="search-box">
                <div
                  className="input-group"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                  }}
                >
                  <Keyboard onInputChange={handleInputChange} competitionId={competitionId} />

                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={handleSubmit}
                  >
                    Send
                  </button>
                </div>
              </div>
            )}

          {Jury_Capabilities.includes("View Candidate Spelling Status") &&
            null}
        </>
      ) : (
        <div className="wrapper">
          <ComponentLoader />
        </div>
      )}
    </>
  );
};

export default JuryView;
