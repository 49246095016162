import React, { useState, useEffect, useMemo } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import countryList from "react-select-country-list";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import {
  GetPartcipantDetails,
  UpdatePartcipants,
} from "../../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../../Store/Types/participantTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import { calculateAge } from "../../../HelperFunctions/AgeFromDob";
import { BASE_URL } from "../../../Config/Config";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const EditParticipantDetails = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo , currentUser } = useSelector((state) => state.Auth);
  const {
    Participant_Loading,
    Participant_Action_Loader,
    participant_Details,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  } = useSelector((state) => state.Participant);

  const { participant_id } = useParams();
  const options = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
    dispatch(
      GetPartcipantDetails({
        createdBy: currentUserInfo._id,
        participantId: participant_id,
      })
    );
  }, [participant_id]);

  // console.log(42, participant_Details);

  /* initial obj */
  const obj = {
    createdBy: currentUserInfo._id,
    participantId: participant_id,
    firstName: participant_Details?.firstName || "",
    lastName: participant_Details?.lastName || "",
    dob: participant_Details?.dob || "",
    grade: participant_Details?.grade || "",
    sex: participant_Details?.sex || "",
    country: participant_Details?.country || "",
    school: participant_Details?.school || "",
    city: participant_Details?.city || "",
    imageFile: "",
    img: participant_Details?.img || "",
    phone: participant_Details?.phone || "",
    email: participant_Details?.email || "",
    additionalNote: participant_Details?.additionalNote || "",
  };
// console.log("hello participants ",obj)
  /* state */
  const [imageFile, setImageFile] = useState(null);
  const [state, setState] = useState(obj);
  const [language, setLanguage] = useState("fr");

  /* Input Change  */
  const handleInputChange = (field, value) => {
    if (field === "dob") {
      const formattedDate = dayjs(value).format("DD-MM-YYYY");
      setState({
        ...state,
        [field]: formattedDate,
      });
    } else if (field === "picture") {
      // Handle image file upload
      console.log("image ",value[0])
      setState({ ...state, ["imgFile"]: value[0] });
      setImageFile(value[0]); // Assuming only one file is selected
    } else {
      setState({
        ...state,
        [field]: value,
      });
    }
  };

  /* Edit Function */
  const handleEdit = () => {
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });

    // Create state object
    const formData = new FormData();
    // Append other form data fields
    formData.append("createdBy", currentUserInfo?._id || "");
    formData.append("participantId", participant_id || "");
    formData.append("firstName", state?.firstName || "");
    formData.append("lastName", state?.lastName || "");
    formData.append("dob", state?.dob || "");
    formData.append("grade", state?.grade || "");
    formData.append("sex", state?.sex || "");
    state.country &&
      state.country.label &&
      formData.append("country_label", state?.country.label);
    state.country &&
      state.country.value &&
      formData.append("country_value", state?.country?.value);
    formData.append("school", state?.school || "");
    formData.append("city", state?.city || "");
    formData.append("phone", state?.phone || "");
    formData.append("email", state?.email || "");
    formData.append("additionalNote", state?.additionalNote || "");
    formData.append("img", participant_Details?.img  || "");

    // Append image file
    if (imageFile) {
      formData.append("imageFile", imageFile);
    }

    // dispatch(ManuallyParticiapntAdd(formData));
    dispatch(UpdatePartcipants(formData));
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Participant_SuccessMessage) {
      const text = MsgTranslator(Participant_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: PARTICIPANT_SUCCESS_MSG_CLEAR });
      navigate(-1);
    }
    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }
    if (Participant_Get_Error_Message) {
      const text = MsgTranslator(Participant_Get_Error_Message,language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
      });
    }
  }, [
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  ]);

  /* for dob input field */
  const dateFormat = (date) => {
    return date.split("-").reverse().join("-");
  };

  useEffect(() => {
    setState({
      ...state,
      ...participant_Details,
    });
  }, [participant_Details]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                {
                  page: MsgTranslator("Participant List",language),
                  link: 2,
                },
                {
                  page: MsgTranslator("Participant Details",language),
                  link: `/participant-details/${participant_id}`,
                },
                {
                  page: MsgTranslator("Edit Participant",language),
                  link: `/edit-participant-details/${participant_id}`,
                },
              ]}
            />

            <div className="upcoming-title">
              <h3>
                <PageTitle title={"<em>Edit Participant</em> Details"} lang={language} />
              </h3>
            </div>
            <div className="candit-sectied-from">
              <div className="user-part-section">
                <div className="user-part">
                  <div className="user-pict">
                    <img
                      src={
                        state.imageFile
                          ? URL.createObjectURL(imageFile)
                          : state.img ||
                            window.location.origin +
                              `/Assets/images/blank-profile-picture.webp`
                      }
                      alt="Testimonial"
                    />
                  </div>
                  <div className="user-name-p">
                    <h2>
                      {state.firstName || "" + " " + state.lastName || ""}
                    </h2>
                    <p>{state.phone}</p>
                  </div>
                </div>
              </div>
              <div className="ft-create-section-two">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="text-from-two">
                      {/* <TranslatedComponent> */}
                        <form>
                          {/* first name */}
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              <span className="nt">01</span>  <TranslatedComponent>First Name :</TranslatedComponent>
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="First Name"
                                placeholder={MsgTranslator(
                                  "First Name",
                                  language
                                )}
                                name="firstName"
                                value={state.firstName}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* last name */}
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              <span className="nt">02</span>  <TranslatedComponent>Last Name :</TranslatedComponent>
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Last Name"
                                placeholder={MsgTranslator(
                                  "Last Name",
                                  language
                                )}
                                name="lastName"
                                value={state.lastName}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* dob */}
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              <span className="nt">03 </span>  <TranslatedComponent>Birthday :</TranslatedComponent>
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["DatePicker", "DatePicker"]}
                                >
                                  <DatePicker
                                    value={
                                      state.dob
                                        ? dayjs(dateFormat(state.dob))
                                        : null
                                    }
                                    onChange={(e) =>
                                      handleInputChange("dob", e)
                                    }
                                    format="DD-MM-YYYY"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                          {/* Age */}
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              <span className="nt">04</span>  <TranslatedComponent>Age :</TranslatedComponent>
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Age"
                                placeholder={MsgTranslator(
                                  "Age",
                                  language
                                )}
                                value={calculateAge(state.dob)}
                              />
                            </div>
                          </div>
                          {/* grade */}
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              <span className="nt">05</span>  <TranslatedComponent>Grade :</TranslatedComponent>
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Grade"
                                placeholder={MsgTranslator(
                                  "Grade",
                                  language
                                )}
                                name="grade"
                                value={state.grade}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* sex */}
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              <span className="nt">06</span>  <TranslatedComponent>Sex :</TranslatedComponent>
                            </label>
                            <div className="col-sm-7">
                              <Select
                                options={[
                                  { label: "Select..", value: "" },
                                  { label: "Male", value: "Male" },
                                  { label: "Female", value: "Female" },
                                ]}
                                value={{ label: state.sex, value: state.sex }}
                                onChange={(e) =>
                                  handleInputChange("sex", e.value)
                                }
                              />
                            </div>
                          </div>
                        </form>
                      {/* </TranslatedComponent> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="text-from-two">
                      <TranslatedComponent>
                        {/* <form> */}
                        {/* school */}
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            <span className="nt">07</span>  <TranslatedComponent>School :</TranslatedComponent>
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="School"
                              placeholder={MsgTranslator(
                                "School",
                                language
                              )}
                              name="school"
                              value={state.school}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {/* city */}
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            <span className="nt">08</span>  <TranslatedComponent>City :</TranslatedComponent>
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="City"
                              placeholder={MsgTranslator(
                                "City",
                                language
                              )}
                              name="city"
                              value={state.city}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {/* contry */}
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            <span className="nt">09</span>  <TranslatedComponent>Country :</TranslatedComponent>
                          </label>
                          <div className="col-sm-7">
                            <Select
                              options={options}
                              value={state.country}
                              onChange={(e) => handleInputChange("country", e)}
                            />
                          </div>
                        </div>
                        {/* Phone number */}
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            <span className="nt">10</span>  <TranslatedComponent>Phone Number :</TranslatedComponent>
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Phone Number"
                              placeholder={MsgTranslator(
                                "Phone Number",
                                language
                              )}
                              name="phone"
                              value={state.phone}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {/*Email */}
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            <span className="nt">11</span>  <TranslatedComponent>Email :</TranslatedComponent>
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Email"
                              placeholder={MsgTranslator(
                                "Email",
                                language
                              )}
                              name="email"
                              value={state.email}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {/* image */}
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            <span className="nt">12</span>  <TranslatedComponent>Picture :</TranslatedComponent>
                          </label>
                          <div className="col-sm-7">
                            <div className="drop-it-hot ">
                              <div className="button-wrapper">
                                <label className="label" htmlFor="fileElem">
                                  {imageFile ? (
                                    <>
                                      <img
                                        src={URL.createObjectURL(imageFile)}
                                        alt="Selected_Image"
                                      />
                                      {imageFile.name}
                                    </>
                                  ) : (
                                    "(Upload Image)"
                                  )}
                                </label>

                                <button type="button" className="btn">
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/lupload.png`
                                    }
                                    alt="Lupload"
                                  />
                                </button>
                                <input
                                  type="file"
                                  id="fileElem"
                                  multiple
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleInputChange("picture", e.target.files) 
                                  }
                                />
                              </div>
                              <div id="gallery"></div>
                            </div>
                          </div>
                        </div>
                        {/* </form> */}
                      </TranslatedComponent>
                    </div>
                  </div>
                  {/*Additional Note */}
                  <div className="col-lg-12">
                    <TranslatedComponent>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="textAreaExample">
                          Additional Note
                        </label>
                        <textarea
                          className="form-control textarea"
                          id="textAreaExample1"
                          rows={4}
                          name="additionalNote"
                          value={state.additionalNote}
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </TranslatedComponent>
                  </div>
                </div>
                <div className="buttaon-lane">
                  <TranslatedComponent>
                    <div className="back-name">
                      <button className="back-g" onClick={() => navigate(-1)}>
                        Back
                      </button>
                    </div>
                    <div className="right-edit">
                      <button className="start-g" onClick={() => handleEdit()}>
                        Submit
                      </button>
                    </div>
                  </TranslatedComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditParticipantDetails;
