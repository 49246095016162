import React, { useState, useEffect } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetWordDetails } from "../../../Store/Action/wordAction";
import {
  WORD_ACTION_LOADER_START,
  WORD_SUCCESS_CLEAR,
  WORD_ERROR_CLEAR,
} from "../../../Store/Types/wordTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { MagnifyingGlass } from "react-loader-spinner";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { BASE_URL } from "../../../Config/Config";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const WordDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { word_id } = params;

  const dispatch = useDispatch();
  const { currentUserInfo , currentUser} = useSelector((state) => state.Auth);
  const {
    Word_Loading,
    Word_Action_Loader,
    Words_All_Data,
    Words_Details,
    Word_SuccessMessage,
    Word_ErrorMessage,
    Word_Get_Error_Message,
  } = useSelector((state) => state.Word);

  useEffect(() => {
    dispatch(GetWordDetails({ wordId: word_id }));
  }, [word_id]);

  const [language, setLanguage] = useState("fr");

  const findNextWordId = (currentId) => {
    let wordsArray = localStorage.getItem("IdArray");
    wordsArray = JSON.parse(wordsArray);

    let nextIndex = 0;
    let currentDataIndex = wordsArray.findIndex((sw) => sw === currentId);

    if (currentDataIndex >= 0 && currentDataIndex < wordsArray.length - 1) {
      nextIndex = currentDataIndex + 1;
    }

    let targetWord = wordsArray[nextIndex];

    return targetWord ? targetWord : null;
  };

  const handleNextWord = () => {
    let nextId = findNextWordId(word_id);
    window.location.href.includes("/phase/")
      ? navigate(`/phase/word-details/${nextId}`)
      : navigate(`/word-details/${nextId}`);
    // navigate(`/word-details/${nextId}`);
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Word_SuccessMessage) {
      // notify(Word_SuccessMessage, "success");
      dispatch({ type: WORD_SUCCESS_CLEAR });
    }
    if (Word_ErrorMessage) {
      const text = MsgTranslator(Word_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: WORD_ERROR_CLEAR });
    }
  }, [Word_SuccessMessage, Word_ErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                ...(window.location.href.includes("/phase/")
                  ? [
                      {
                        page: MsgTranslator("Phase Manage",language),
                        link: 2,
                      },
                    ]
                  : []), // conditional
                {
                  page: MsgTranslator("Word List",language),
                  link: 1,
                },
                {
                  page: MsgTranslator("Word Details",language),
                  link: 0,
                },
              ]}
            />
            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em> </em>{" "}
                  </TranslatedComponent>
                  <TranslatedComponent></TranslatedComponent>
                </span> */}
                  <PageTitle title={"<em>Word</em> Details"} lang={language} />
              </h3>
            </div>
            <div className="start-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div id="txt" />
                    <TranslatedComponent>
                    <div className="candit-sectied-from">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="theme-m">
                            <strong>Theme :</strong>
                            <p>{Words_Details?.theme || ""}</p>
                          </div>
                          <div className="theme-m">
                            <strong>Context :</strong>
                            <p>{Words_Details?.context || ""}</p>
                          </div>
                        </div>
                        <div className="col-lg-6"></div>
                      </div>
                      <div className="caption-one">
                        <div className="capap-t">
                          <ul>
                            <li>
                              <span>Word :</span>{" "}
                              <p>{Words_Details?.word || ""} </p>
                            </li>
                            <li>
                              <span>Spelling Alternatives :</span>{" "}
                              <p>{Words_Details?.spellingAlternatives || ""}</p>
                            </li>
                            <li>
                              <span>Word Phonetics :</span>{" "}
                              <p>{Words_Details?.word_phonetics || ""}</p>
                            </li>
                            <li>
                              <span>Origin :</span>{" "}
                              <p>{Words_Details?.origin || ""}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="caption-two">
                        <div className="capap-t">
                          <ul>
                            <li>
                              <span>Nature :</span>{" "}
                              <p>{Words_Details?.nature || ""}</p>
                            </li>
                            <li>
                              <span>Definition :</span>{" "}
                              <p>{Words_Details?.definition || ""}</p>
                            </li>
                            <li>
                              <span>Illustrative Sentence :</span>{" "}
                              <p>
                                {Words_Details?.illustrativeSentence || ""}{" "}
                              </p>
                            </li>
                            <li>
                              <span>Homonyms :</span>{" "}
                              <p>{Words_Details?.homonyms || ""}</p>
                            </li>
                            <li>
                              <span>Gender :</span>{" "}
                              <p>{Words_Details?.gender || ""}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="buttaon-lane">
                        <div className="back-name">
                          <button
                            className="back-g"
                            onClick={() => navigate(-1)}
                          >
                            Back
                          </button>
                        </div>
                        <div className="right-edit">
                          <button
                            className="edit-g"
                            onClick={
                              () =>
                                window.location.href.includes("/phase/")
                                  ? navigate(
                                      `/phase/edit-word-details/${Words_Details._id}`
                                    )
                                  : navigate(
                                      `/edit-word-details/${Words_Details._id}`
                                    )
                              // navigate(
                              //   `/edit-word-details/${Words_Details._id}`
                              // )
                            }
                          >
                            Edit
                          </button>
                          <button
                            className="start-g"
                            onClick={() => handleNextWord()}
                          >
                            Go to Next Word
                          </button>
                        </div>
                      </div>
                    </div>
                    </TranslatedComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WordDetails;
