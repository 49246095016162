import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SidebarLeft from "../../../../component/Sideber/SidebarLeft";
import Header from "../../../../component/Header/Header";

import { useSelector, useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import {
  GetAllOneShot,
  UpdateOneShot,
  GetOneShotDetails,
} from "../../../../Store/Action/oneShotAction";
import {
  ONESHOT_ACTION_LOADER,
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
} from "../../../../Store/Types/oneShotTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../../component/ToastMsg/ToastMsg";
import axios from "axios";
import { SERVER_URL } from "../../../../Config/Config";
import TranslatedComponent from "../../../../translator/TranslatedComponent";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../../HelperFunctions/MsgTranslator";

const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  // console.log(`${formattedHours}`:${formattedMinutes}`:${formattedSeconds}`)
  return `${formattedMinutes}:${formattedSeconds}`;
};

const EditOneShotCompetition = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser} = useSelector((state) => state.Auth);
  const {
    OneShot_Loading,
    OneShot_Action_Loader,
    OneShot_Data,
    OneShotDetails,
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
    OneShot_Get_Error_Message,
  } = useSelector((state) => state.OneShot);

  const [themeContextOptions, setThemeContext] = useState({
    themes: [],
    contexts: [],
  });
  const [language , setLanguage] = useState('fr');

  /* initial state obj */
  const obj = {
    oneshotId: id,
    adminId: currentUserInfo?._id || null,
    orgId: currentUserInfo?._id || null,
    // tournamentId: null,
    name: "",
    date: "",
    time: "",
    themeOfWords: "",
    wordContext: "",
    difficulty: "",
    totalParticipants: 0,
    qualifiedParticipants: 0,
    altTime: "",
    allotedTime: 0,
  };

  /* state for Form */
  const [state, setState] = useState(obj);

  /* getting all org oneshot */
  useEffect(() => {
    axios.get(`${SERVER_URL}/word/get-theme-context`)
      .then((res) => {
        const uniqueThemes = [...new Set(res.data.data.themes.map(theme => theme.toLowerCase()))];
        const uniqueContexts = [...new Set(res.data.data.contexts.map(context => context.toLowerCase()))];
        setThemeContext({
          themes: uniqueThemes,
          contexts: uniqueContexts
        });

      })
      .catch((err) => {
        console.log("Error: " + err);
      });
  }, []);

  /* getting current oneshot by mapping from all oneshot */
  useEffect(() => {
    if (OneShotDetails && Object.keys(OneShotDetails).length > 0) {
      setState(OneShotDetails);
      setState({
        ...state,
        ...OneShotDetails,
        altTime: formatTime(OneShotDetails.allotedTime),
      });
    }
  }, [OneShotDetails]);

  /* form input handle */
  const handleChange = (name, value) => {
    if (name === "date") {
      const formattedDate = dayjs(value).format("DD-MM-YYYY");
      // console.log(41, formattedDate);
      setState({
        ...state,
        [name]: formattedDate,
      });
    } else if (name === "time") {
      const formattedDate = dayjs(value).format("hh:mm a");
      setState({
        ...state,
        [name]: formattedDate,
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  /* save button */
  const handleSave = (e) => {
    e.preventDefault();
    let totalsec = 0;
    if (!state.time || !state.date || !state.altTime) {
      const title = MsgTranslator("All fields are required",language)
      // notify("All fields are required", "error");
      notify(title, "error");
      return;
    }
    if (state.altTime) {
      // console.log(state.altTime);
      const time = state.altTime.split(":");
      if (time.length !== 2) {
        const title = MsgTranslator("Alloted time format mismatched, i.e. 03:20 - 03min 20sec",language)
        notify(
          title,
          "error"
        );
        setState({
          ...state,
          altTime: "",
        });
        return;
      }
      let timer = {
        min: Number(time[0].trim()),
        sec: Number(time[1].trim()),
      };
      // console.log(timer, time[0].trim().length, time[1].trim().length);
      if (
        timer.min >= 60 ||
        timer.sec >= 60 ||
        time[0].trim().length > 2 ||
        time[1].trim().length !== 2
      ) {
        const title = MsgTranslator("Alloted time format mismatched, i.e. 03:20 - 03min 20sec",language)
        notify(
          title,
          "error"
        );
        // notify(
        //   "Alloted time format mismatched, i.e. 03:20 - 03min 20sec",
        //   "error"
        // );
        setState({
          ...state,
          altTime: "",
        });
        return;
      }
      totalsec = timer.min * 60 + timer.sec;
      // console.log(totalsec);
      setState({
        ...state,
        allotedTime: totalsec,
      });
    }
    // console.log(totalsec);
    dispatch({ type: ONESHOT_ACTION_LOADER });
    dispatch(UpdateOneShot({ ...state, allotedTime: totalsec }));
  };

  /* success and error msg handle */
  useEffect(() => {
    if (OneShot_SuccessMessage) {
      const title =  MsgTranslator(OneShot_SuccessMessage,language);
      // notify(OneShot_SuccessMessage, "success");
      notify(title, "success");
      dispatch({ type: ONESHOT_SUCCESS_CLEAR });
      navigate(-1);
    }
    if (OneShot_ErrorMessage) {
      const title =  MsgTranslator(OneShot_ErrorMessage,language);
      notify(title, "error");
      // notify(OneShot_ErrorMessage, "error");
      dispatch({ type: ONESHOT_ERROR_CLEAR });
    }

    if (OneShot_Get_Error_Message) {
      const title =  MsgTranslator(OneShot_Get_Error_Message,language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: title,
        // text: OneShot_Get_Error_Message,
      }).then(() => {
        dispatch({ type: ONESHOT_ERROR_CLEAR });
      });
    }
  }, [OneShot_SuccessMessage, OneShot_ErrorMessage, OneShot_Get_Error_Message]);

  /* date formation for form */
  const dateFormat = (date) => {
    return date.split("-").reverse().join("-");
  };

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                {
                  page: MsgTranslator("OneShot Details",language),
                  link: `/one-shot-details/${id}`,
                },
                {
                  page: MsgTranslator("OneShot Modify",language), 
                  link: `/edit-one-shot-competition/${id}`,
                },
              ]}
            />
            <div className="upcoming-title">
              {state.tournamentId ? (
                <h3>
                  <span>
                    <PageTitle title={"<em>Edit Sub</em> Competition"} lang={language} />
                  </span>
                </h3>
              ) : (
                <h3>
                  <PageTitle title={"<em>Edit One Shot</em> Competition"} lang={language} />
                </h3>
              )}
            </div>
            <TranslatedComponent>
            <div className="candit-sectied-from">
              {state.tournamentId ? (
                <h4>Edit Sub Competition</h4>
              ) : (
                <h4>Edit One Shot Competition</h4>
              )}
              <form method="POST" onSubmit={handleSave}>
                <div className="ft-create-section">
                  <div className="text-from-one">
                    <form>
                      <div className="row">
                        {/* name */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Name :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Name"
                                placeholder={MsgTranslator(
                                  "Name",
                                  language
                                )}
                                value={state.name}
                                name="name"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Date */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Date :
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["DatePicker", "DatePicker"]}
                                >
                                  <DatePicker
                                    value={
                                      state.date
                                        ? dayjs(dateFormat(state.date))
                                        : null
                                    }
                                    onChange={(e) => handleChange("date", e)}
                                    format="DD-MM-YYYY"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>

                        {/* Time */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Time:
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["TimePicker", "TimePicker"]}
                                >
                                  <TimePicker
                                    // label="Controlled picker"
                                    value={dayjs(state.time, "hh:mm a")}
                                    onChange={(newValue) =>
                                      handleChange("time", newValue)
                                    }
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Theme Of the word */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Theme of The Words :
                            </label>
                            <div className="col-sm-7">
                              {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Theme of The Words"
                                value={state.themeOfWords}
                                name="themeOfWords"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                              /> */}
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                // className="form-control"
                                // placeholder="Theme of The Words"
                                placeholder={MsgTranslator(
                                  "Theme of The Words",
                                  language
                                )}
                                name="themeOfWords"
                                value={state.themeOfWords}
                                options={themeContextOptions?.themes.filter(
                                  Boolean
                                )}
                                // sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // label={"Select a theme"}
                                    required
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  handleChange("themeOfWords", newValue); // Pass the name directly
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Word Context*/}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Word Context :
                            </label>
                            <div className="col-sm-7">
                              {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Word Context"
                                value={state.wordContext}
                                name="wordContext"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                              /> */}
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                // className="form-control"
                                // placeholder="Theme of The Words"
                                placeholder={MsgTranslator(
                                  "Theme of The Words",
                                  language
                                )}
                                name="wordContext"
                                value={state.wordContext}
                                options={themeContextOptions?.contexts.filter(
                                  Boolean
                                )}
                                // sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // label={"Select a Context"}
                                    required
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  handleChange("wordContext", newValue); // Pass the name directly
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Word Level of Difficulty*/}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Word Level of Difficulty :
                            </label>
                            <div className="col-sm-7">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                value={state.difficulty}
                                name="difficulty"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                              >
                                <option selected="Easy">Easy</option>
                                <option value="Moderate">Moderate</option>
                                <option value="Hard">Hard</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Number of Participants */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Number of Participants :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="number"
                                className="form-control"
                                // placeholder="Number of Participants"
                                placeholder={MsgTranslator(
                                  "Number of Participants",
                                  language
                                )}
                                value={state.totalParticipants}
                                name="totalParticipants"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Number of Candidates To Qualify */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Number of Candidates To Qualify :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="number"
                                className="form-control"
                                // placeholder="Number of Candidates To Qualify"
                                placeholder={MsgTranslator(
                                  "Number of Candidates To Qualify",
                                  language
                                )}
                                value={state.qualifiedParticipants}
                                name="qualifiedParticipants"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/* alloted time */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Alloted Time Per Candidate :
                            </label>
                            <div className="col-sm-7">
                              <input
                                // type="number"
                                className="form-control"
                                // placeholder="e.g - 02:10"
                                placeholder={MsgTranslator(
                                  "e.g - 02:10",
                                  language
                                )}
                                name="altTime"
                                value={state.altTime}
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="buttaon-lane">
                  <div className="back-name">
                    <button
                      className="back-g"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="right-edit">
                    <button className="start-g" type="submit">
                      {OneShot_Action_Loader && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "-10%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}{" "}
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
            </TranslatedComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOneShotCompetition;
