import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
// import { BASE_URL } from "../../../Config/Config";

import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";
import countryList from "react-select-country-list";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import MenuItem from '@mui/material/MenuItem';

import { ManuallyParticiapntAdd } from "../../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../../Store/Types/participantTypes";
// import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";
// import { getImageUrl } from "../../../HelperFunctions/CloudenaryUpload";

const AddParticipant = () => {
  const params = useParams();
  const { comp_id } = params;

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser} = useSelector((state) => state.Auth);
  const {
    Participant_Action_Loader,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
  } = useSelector((state) => state.Participant);

  const [imageFile, setImageFile] = useState(null);
  const [language, setLanguage] = useState("fr");

  const options = useMemo(() => countryList().getData(), []);

  const navigate = useNavigate();

  const [state, setState] = useState({
    createdBy: currentUserInfo._id,
    compId: comp_id,
    firstName: "",
    lastName: "",
    dob: "",
    grade: "",
    sex: "",
    country: "",
    school: "",
    city: "",
    imageFile: "",
    phone: "",
    email: "",
  });

  /* Input Change  */
  const handleInputChange = (field, value) => {
    // console.log(field, value);
    if (field === "dob") {
      const formattedDate = dayjs(value).format("DD-MM-YYYY");
      setState({
        ...state,
        [field]: formattedDate,
      });
    } else if (field === "picture") {
      // Handle image file upload
      setState({ ...state, ["imgFile"]: value[0] });
      setImageFile(value[0]); // Assuming only one file is selected
    } else {
      setState({
        ...state,
        [field]: value,
      });
    }
  };

  /* save */
  const handleSave = async () => {
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });

    // Create state object
    const formData = new FormData();

    // Append other form data fields
    formData.append("createdBy", currentUserInfo._id);
    formData.append("compId", comp_id);
    state.firstName && formData.append("firstName", state.firstName);
    state.lastName && formData.append("lastName", state.lastName);
    state.dob && formData.append("dob", state.dob);
    state.grade && formData.append("grade", state.grade);
    state.sex && formData.append("sex", state.sex);
    state?.country?.label &&
      formData.append("country_label", state?.country.label);
    state?.country?.value &&
      formData.append("country_value", state?.country?.value);
    state.school && formData.append("school", state.school);
    state.city && formData.append("city", state.city);
    state.phone && formData.append("phone", state.phone);
    state.email && formData.append("email", state.email);

    // Append image file
    if (imageFile) {
      formData.append("imageFile", imageFile);
    }
    // const url = await getImageUrl(imageFile);
    // console.log(...formData);
    dispatch(ManuallyParticiapntAdd(formData));
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Participant_SuccessMessage) {
      const text = MsgTranslator(Participant_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: PARTICIPANT_SUCCESS_MSG_CLEAR });
      window.location.href.includes("/phase/")
      ? navigate(`/phase/all-participant/${comp_id}`)
      : navigate(`/all-participant/${comp_id}`);
      
      // navigate(`/all-participant/${comp_id}`);
    }
    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }
  }, [Participant_SuccessMessage, Participant_ErrorMessage]);

  /* for dob input field */
  const dateFormat = (date) => {
    return date.split("-").reverse().join("-");
  };

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                {
                  page: MsgTranslator("Participant List",language),
                  link: `/all-participant/${comp_id}`,
                },
                {
                  page: MsgTranslator("Manual Participant Add",language),
                  link: `/all-participant/${comp_id}`,
                },
              ]}
            />
            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em>Add</em>
                  </TranslatedComponent>
                  <TranslatedComponent>Participant</TranslatedComponent>
                </span> */}
                  <PageTitle title={"<em>Add</em> Participant"} lang={language} />
              </h3>
            </div>
            <TranslatedComponent>
              <div className="candit-sectied-from">
                <h4>Add Participant</h4>

                <div className="ft-create-section">
                  <h2>Participant Add</h2>

                  <div className="participant-section">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          {/* first name */}
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              First Name :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="First Name"
                                placeholder={MsgTranslator(
                                  "First Name",
                                  language
                                )}                                
                                name="firstName"
                                value={state.firstName}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* last name */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          {/* <form> */}
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              Last Name:
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Last Name"
                                placeholder={MsgTranslator(
                                  "Last Name",
                                  language
                                )}
                                name="lastName"
                                value={state.lastName}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                      {/* Age/Birth Date */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              Age/Birth Date :
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["DatePicker", "DatePicker"]}
                                >
                                  <DatePicker
                                    value={
                                      state.dob
                                        ? dayjs(dateFormat(state.dob))
                                        : null
                                    }
                                    onChange={(e) =>
                                      handleInputChange("dob", e)
                                    }
                                    format="DD-MM-YYYY"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="participant-section">
                    <div className="row">
                      {/* Grade */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              Grade :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Grade"
                                placeholder={MsgTranslator("Grade", language)}
                                name="grade"
                                value={state.grade}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* sex */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              Sex :
                            </label>
                            <div className="col-sm-7">
                              <Select
                                options={[
                                  { label: "Select..", value: "" },
                                  { label: "Male", value: "Male" },
                                  { label: "Female", value: "Female" },
                                ]}
                                value={{
                                  label: state.sex ? state.sex : "Select..",
                                  value: state.sex,
                                }}
                                onChange={(e) =>
                                  handleInputChange("sex", e.value)
                                }
                              />
                            </div>
                          </div>

                          {/* </form> */}
                        </div>
                      </div>

                      {/* Country */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Country :
                            </label>

                            <div className="col-sm-7">
                              <Select
                                options={options}
                                value={state.country}
                                onChange={(e) =>
                                  handleInputChange("country", e)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="participant-section">
                    <div className="row">
                      {/* school */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          {/* <form> */}
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              School :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="School"
                                placeholder={MsgTranslator(
                                  "School",
                                  language
                                )}
                                name="school"
                                value={state.school}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                      {/* City */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          {/* <form> */}
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              City :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="City"
                                placeholder={MsgTranslator(
                                  "City",
                                  language
                                )}
                                name="city"
                                value={state.city}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                      {/* Picture */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          {/* <form> */}
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              Picture :
                            </label>
                            <div className="col-sm-7">
                              <div className="drop-it-hot ">
                                <div className="button-wrapper">
                                  <label className="label" htmlFor="fileElem">
                                    {imageFile ? (
                                      <>
                                        <img
                                          src={URL.createObjectURL(imageFile)}
                                          alt="Selected_Image"
                                        />
                                        {imageFile.name}
                                      </>
                                    ) : (
                                      "(Upload Image)"
                                    )}
                                  </label>
                                  <button type="button" className="btn">
                                    <img
                                      src={
                                        window.location.origin +
                                        `/Assets/images/lupload.png`
                                      }
                                      alt="Lupload"
                                    />
                                  </button>
                                  <input
                                    type="file"
                                    id="fileElem"
                                    multiple
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleInputChange(
                                        "picture",
                                        e.target.files
                                      )
                                    }
                                  />
                                </div>
                                {/* <div id="gallery">
                                </div> */}
                              </div>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="participant-section">
                    <div className="row">
                      {/* phone */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          {/* <form> */}
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              Phone :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Phone"
                                placeholder={MsgTranslator(
                                  "Phone",
                                  language
                                )}
                                value={state.phone}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                      {/* email */}
                      <div className="col-lg-4">
                        <div className="text-from-two">
                          {/* <form> */}
                          <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                              Email :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder=" Email"
                                placeholder={MsgTranslator(
                                  "Email",
                                  language
                                )}
                                name="email"
                                value={state.email}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button className="add-p" onClick={handleSave}>
                  {Participant_Action_Loader && (
                    <CircularProgress
                      size={24}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "-10%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                  <img
                    src={window.location.origin + `/Assets/images/add-p.png`}
                    alt="Add"
                  />{" "}
                  Add Participant
                </button>
              </div>
            </TranslatedComponent>

            {/* <div className="buttaon-lane">
              <button className="back-g">
                Back
              </button>
              <button className="start-g" onClick={handleSave}>
                Save
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddParticipant;
