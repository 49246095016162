import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";

import Select from "react-select";
import countryList from "react-select-country-list";

import { useSelector, useDispatch } from "react-redux";
import { UserDataUpdate, DeleteUser } from "../../../Store/Action/userAction";
import { GetUserDetails } from "../../../Store/Action/authAction";
// import { AUTH_SUCCESS_CLEAR, AUTH_ERROR_CLEAR } from "../../../Store/Types/authTypes";
import {
  USER_SUCCESS_CLEAR,
  USER_ERROR_CLEAR,
} from "../../../Store/Types/userTypes";
import Swal from "sweetalert2";

import { notify } from "../../../component/ToastMsg/ToastMsg";
import "react-toastify/dist/ReactToastify.css";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const MyAccountOrganizer = () => {
  const navigate = useNavigate();

  const options = useMemo(() => countryList().getData(), []);

  const dispatch = useDispatch();
  const {
    authLoading,
    authenticate,
    currentUser,
    authSuccessMessage,
    authErrorMessage,
  } = useSelector((state) => state.Auth);
  const {
    userLoading,
    userSuccessMessage,
    userErrorMessage,
    userDelSuccessMessage,
    userDelErrorMessage,
  } = useSelector((state) => state.User);

  let user = {
    adminId: currentUser?._id || null,
    userId: currentUser?._id || null,
    role: "Org",
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    phone: currentUser?.phone || "",
    country: currentUser?.country || "",
    email: currentUser?.email || "",
    organizationType: currentUser?.organizationType || "",
    organizationName: currentUser?.organizationName || "",
    address: currentUser?.address || "",
    locality: currentUser?.locality || "",
    language: currentUser?.language || "",
    metaDatas: [],
  };

  const [state, setState] = useState(user);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    setState({
      ...state,
      adminId: currentUser?._id || null,
      userId: currentUser?._id || null,
      role: currentUser?.role || "Org",
      firstName: currentUser?.firstName || "",
      lastName: currentUser?.lastName || "",
      phone: currentUser?.phone || "",
      country: currentUser?.country || "",
      email: currentUser?.email || "",
      organizationType: currentUser?.organizationType || "",
      organizationName: currentUser?.organizationName || "",
      address: currentUser?.address || "",
      locality: currentUser?.locality || "",
      language: currentUser?.language || "",
      metaDatas: [],
    });
  }, [currentUser]);

  // Function to update metaData field
  const updateMetaData = (key, value) => {
    const existingIndex = state.metaDatas.findIndex(
      (item) => item.metaKey === key
    );
    const updatedMetaData = [...state.metaDatas];

    if (existingIndex !== -1) {
      updatedMetaData[existingIndex].metaValue = value;
    } else {
      updatedMetaData.push({
        metaKey: key,
        metaValue: value,
      });
    }

    return updatedMetaData;
  };

  /* Change in FORM  */
  const handleChange = (name, value) => {
    if (name === "country") {
      setState({ ...state, [name]: value });
    } else {
      // Update metaData if the field is in the list
      if (
        [
          "organizationType",
          "organizationName",
          "address",
          "locality",
        ].includes(name)
      ) {
        const updatedMetaData = updateMetaData(name, value);
        setState({ ...state, [name]: value, metaDatas: updatedMetaData });
      } else {
        setState({ ...state, [name]: value });
      }
    }
  };

  const handleUserUpdate = () => {
    dispatch(UserDataUpdate(state));
  };

  const handleDelete = () => {
    const title = MsgTranslator('Are you sure?', language)
    // currentUser && currentUser.lenguage === "en"
    //   ? "Are you sure?"
    //   : "Es-tu sûr?";
    const text = MsgTranslator("You won't be able to revert this!", language)
    // currentUser && currentUser.lenguage === "en"
    //   ? "You won't be able to revert this!"
    //   : "Vous ne pourrez pas revenir en arrière !";
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: MsgTranslator("Yes, delete it!", language)
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          adminId: currentUser._id,
          userId: currentUser._id,
        };
        dispatch(DeleteUser(obj));
      }
    });
  };

  useEffect(() => {
    if (userSuccessMessage) {
      const text = MsgTranslator(userSuccessMessage, language)
      notify(text, "success");

      if (localStorage.getItem("userId")) {
        dispatch(GetUserDetails(localStorage.getItem("userId")));
      }

      dispatch({ type: USER_SUCCESS_CLEAR });
    }
    if (userErrorMessage) {
      const text = MsgTranslator(userErrorMessage, language)
      notify(text, "error");
      dispatch({ type: USER_ERROR_CLEAR });
    }

    if (userDelSuccessMessage) {
      dispatch({ type: USER_SUCCESS_CLEAR });
      navigate("/");
    }
    if (userDelErrorMessage) {
      const text = MsgTranslator(userDelErrorMessage, language)
      notify(text, "error");
      dispatch({ type: USER_ERROR_CLEAR });
    }
  }, [
    userSuccessMessage,
    userErrorMessage,
    userDelSuccessMessage,
    userDelErrorMessage,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                <span>
                  {/* <TranslatedComponent>
                    <em></em>{" "}
                  </TranslatedComponent>
                  <TranslatedComponent></TranslatedComponent> */}
                  <PageTitle title={"<em>My Account</em> Organizer"} lang={language} />
                </span>
              </h3>
            </div>
            <div className="candit-sectied-from">
              <div className="ft-create-section">
                <div className="text-from-one">
                  <div className="row">
                    {/* first name */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>
                            First Name : *
                          </TranslatedComponent>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder="Name"
                            placeholder={MsgTranslator(
                              "Name",
                              language
                            )}
                            name="firstName"
                            value={state.firstName}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* last name */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>Last Name : *</TranslatedComponent>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder="Date"
                            placeholder={MsgTranslator(
                              "Date",
                              language
                            )}
                            name="lastName"
                            value={state.lastName}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* country */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>Country :</TranslatedComponent>
                        </label>

                        <div className="col-sm-7">
                          <Select
                            options={options}
                            value={state.country}
                            onChange={(e) => handleChange("country", e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* Organization */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>
                            Organization Name :
                          </TranslatedComponent>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder="Organization Name"
                            placeholder={MsgTranslator(
                              "Organization Name",
                              language
                            )}
                            name="organizationName"
                            value={state.organizationName}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* Organization Type */}
                    {state.role === "Org" && (
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            <TranslatedComponent>
                              Organization Type :
                            </TranslatedComponent>
                          </label>
                          <div className="col-sm-7">
                            <TranslatedComponent>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="organizationType"
                                value={state.organizationType}
                                onChange={(e) =>
                                  handleChange(e.target.name, e.target.value)
                                }
                                required
                              >
                                <option value="Teacher">Teacher</option>
                                <option value="School">School</option>
                                <option value="NGO">NGO</option>
                                <option value="Agency">Agency</option>
                              </select>
                            </TranslatedComponent>
                          </div>

                        </div>
                      </div>
                    )}
                    {/* email */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>Email :</TranslatedComponent>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder="Email"
                            placeholder={MsgTranslator(
                              "Email",
                              language
                            )}                
                            name="email"
                            value={state.email}
                            // onChange={(e) =>
                            //   handleChange(e.target.name, e.target.value)
                            // }
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* locality */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>Locality :</TranslatedComponent>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder="Locality"
                            placeholder={MsgTranslator(
                              "Locality",
                              language
                            )}
                            name="locality"
                            value={state.locality}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* Phone */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>Phone Number :</TranslatedComponent>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder="Phone Number"
                            placeholder={MsgTranslator(
                              "Phone Number",
                              language
                            )}
                            name="phone"
                            value={state.phone}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* Address */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>Address :</TranslatedComponent>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder="Address"
                            placeholder={MsgTranslator(
                              "Address",
                              language
                            )}
                            name="address"
                            value={state.address}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* languange */}
                    <div className="col-lg-4">
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-5 col-form-label"
                        >
                          <TranslatedComponent>Language :</TranslatedComponent>
                        </label>
                        <div className="col-sm-7">
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            name="language"
                            value={state.language}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            required
                          >
                            <option value="fr">French</option>
                            <option value="en">English</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row"></div>*/}
                </div>
              </div>
              <TranslatedComponent>
                <div className="dt-admin">
                  <button className="delete-0" onClick={() => handleDelete()}>
                    Delete
                  </button>
                  <button className="save-0" onClick={() => handleUserUpdate()}>
                    Save
                  </button>
                </div>
              </TranslatedComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccountOrganizer;
