import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import PlayerSerialNumber from "../../../component/PlayerSerialNumber/PlayerSerialNumber";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import { convertSecondsToTime } from "../../../HelperFunctions/ConvertSec.js";

import {
  GetCurrentRound,
  // Record_Word_Answer,
  Update_Result_for_round,
} from "../../../Store/Action/roundAction";
import {
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
} from "../../../Store/Types/roundTypes";

import { GetJuryCapability } from "../../../Store/Action/juryAction";
import { GetWordDetails } from "../../../Store/Action/wordAction";
import { WORD_ERROR_CLEAR } from "../../../Store/Types/wordTypes";

import Swal from "sweetalert2";

import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
} from "../../../HelperFunctions/Socket/socket.js";
import TranslatedComponent from "../../../translator/TranslatedComponent.jsx";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";

const JuryValidate = () => {
  const { round_id, player_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);

  const {
    round_Loading,
    current_round_Details,
    round_Action_Loader,
    round_SuccessMessage,
    round_ErrorMessage,
  } = useSelector((state) => state.Round);

  const { Word_Loading, Words_Details, Word_Get_Error_Message } = useSelector(
    (state) => state.Word
  );

  const { Jury_Loading, Jury_Action_Loader, Jury_Capabilities } = useSelector(
    (state) => state.Jury
  );

  const [pageState, setPageState] = useState(false);
  const [language, setLanguage] = useState("fr");

  /* Current candidate state */
  const [candidate, setCandidate] = useState({});

  /* Result state and taking result  */
  const [result, setResult] = useState(null);
  const handleValidate = (value) => {
    setResult(value);
  };

  /* get round details wrt round_id */
  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetCurrentRound({ roundId: round_id }));
    StartSocketConnection();
  }, [round_id]);

  /* Get unused Words and get word Details */
  useEffect(() => {
    if (
      Object.keys(current_round_Details).length > 0 &&
      current_round_Details.participants &&
      current_round_Details.participants.length > 0
    ) {
      if (currentUserInfo.role === "Jury") {
        dispatch(
          GetJuryCapability({
            juryId: currentUserInfo._id,
            compId: current_round_Details.compId._id,
          })
        );
      }

      // socket connection

      if (
        current_round_Details.compId._id !== undefined &&
        current_round_Details.compId._id !== null
      ) {
        JoinRoom(current_round_Details.compId._id, currentUserInfo._id);
        pageChange(
          current_round_Details.compId._id,
          `/jury-validate/${round_id}/${player_id}`
        );
      }

      let targetParticipant = current_round_Details.participants.find(
        (participant) => participant.playerId._id.toString() === player_id
      );
      // console.log(
      //   targetParticipant,
      //   targetParticipant.words[targetParticipant.words.length - 1]
      // );
      setCandidate(targetParticipant);
      if (
        targetParticipant.words &&
        targetParticipant.words.length > 0 &&
        targetParticipant.words[targetParticipant.words.length - 1].wordId
      ) {
        // console.log(
        //   targetParticipant.words[targetParticipant.words.length - 1].wordId
        // );
        dispatch(
          GetWordDetails({
            wordId:
              targetParticipant.words[targetParticipant.words.length - 1]
                .wordId,
          })
        );
      }
    }
  }, [current_round_Details]);

  pageQuery(({ competitionId, page }) => {
    setPageState(page);
  });

  useEffect(() => {
    if (pageState) {
      navigate(pageState);
    }
  }, [pageState]);

   /* get current candidate index */
   const CurrentCandidateIndex = (player_id) => {
    const PlayerSerial = current_round_Details?.participants.findIndex(
      (sp) => sp.playerId._id.toString() === player_id
    );
    // console.log(PlayerSerial);
    // console.log(
    //   current_round_Details?.participants[PlayerSerial + 1]?.playerId?._id
    // );
    if (
      PlayerSerial != -1 &&
      current_round_Details?.participants.length > PlayerSerial + 1
    ) {
      const nextPlayerId =
        current_round_Details?.participants[PlayerSerial + 1]?.playerId?._id;
        
      return {
        curSerial: PlayerSerial + 1,
        nextSerial: nextPlayerId,
      };
    } else if (
      PlayerSerial != -1 &&
      current_round_Details?.participants.length === PlayerSerial + 1
    ) {
      return { curSerial: PlayerSerial + 1, nextSerial: null };
    } else {
      return {
        curSerial: null,
        nextSerial: null,
      };
    }
  };

  /* submit */
  const handleSubmit = () => {
    // console.log(player_id);
    let { curSerial, nextSerial } = CurrentCandidateIndex(player_id);
    // console.log("submitting...");
    const obj = {
      compId: current_round_Details.compId._id,
      playerId: player_id,
      roundId: round_id,
      wordId: Words_Details._id.toString(),
      result: result,
      qualifyingStatus: result ? "Qualified" : "Eliminated",
      score: result ? 1 : 0,
      isCompleted: nextSerial ? false : true,
    };
    // console.log(obj);
    dispatch(Update_Result_for_round(obj));
  };

  /* message */
  useEffect(() => {
    if (round_SuccessMessage) {
      dispatch({ type: ROUND_SUCCESS_MSG_CLEAR });

      pageChange(
        current_round_Details.compId._id,
        `/candidate-spelling-status/${round_id}/${player_id}/${Words_Details._id}`
      );
      navigate(
        `/candidate-spelling-status/${round_id}/${player_id}/${Words_Details._id}`
      );
    }
    if (Word_Get_Error_Message) {
      const text = MsgTranslator(Word_Get_Error_Message, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: WORD_ERROR_CLEAR });
      });
    }
    if (round_ErrorMessage) {
      const text = MsgTranslator(round_ErrorMessage, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: ROUND_ERROR_MSG_CLEAR });
      });
    }
  }, [Word_Get_Error_Message, round_SuccessMessage, round_ErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            {!round_Loading &&
              !Word_Loading &&
              Object.keys(current_round_Details).length > 0 &&
              Object.keys(Words_Details).length > 0 &&
              Object.keys(candidate).length > 0 &&
              candidate?.words.length > 0 ? (
              <>
                <div className="upcoming-title">
                  <h3>
                    {/* <span>
                      <TranslatedComponent>
                      <em></em>{" "}
                      </TranslatedComponent>
                      <TranslatedComponent>
                      
                      </TranslatedComponent>
                    </span> */}
                    <PageTitle
                      title={"<em>Jury Validate</em> Candidate Response"}
                      lang={language}
                    />
                  </h3>
                </div>
                <div className="candit-sectied-from">
                  <TranslatedComponent>
                    <h4>Jury Validate Candidate Response</h4>
                    <div className="ft-create-section">
                      <div className="row justify-content-center notification-header">
                        <div className="col-lg-6">
                          <div className="text-from-one">
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Competition Name :
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {current_round_Details?.compId?.name || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Candidate Number :
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {Object.keys(current_round_Details).length >
                                    0 &&
                                    current_round_Details.participants &&
                                    player_id ? (
                                    <PlayerSerialNumber
                                      compId={
                                        current_round_Details?.compId?._id
                                      }
                                      playerId={player_id}
                                    />
                                  ) : (
                                    ""
                                  )}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Candidate Name :
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {candidate?.playerId?.firstName ||
                                    "" + " " + candidate?.playerId?.lastName ||
                                    ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                No of Round :
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {current_round_Details?.name || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Competition Type :
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {current_round_Details?.compId?.tournamentId
                                    ? "Tournament"
                                    : "OneShot"}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                word :
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {Words_Details?.word || ""}
                                  {Words_Details?.spellingAlternatives &&
                                    Words_Details?.spellingAlternatives !==
                                    "None" &&
                                    Words_Details?.spellingAlternatives !==
                                    "NA" && Words_Details?.spellingAlternatives !==
                                    "-" &&
                                    Words_Details?.spellingAlternatives !== "N/A"
                                    ? ` / ${Words_Details?.spellingAlternatives}`
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Word Spelt :
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {candidate?.words[candidate?.words.length - 1]
                                    ?.answer || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Spelling Duration :
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {convertSecondsToTime(
                                    candidate?.words[
                                      candidate?.words.length - 1
                                    ]?.speltTime || ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {currentUserInfo.role === "Org" ||
                          (currentUserInfo.role === "Jury" &&
                            Jury_Capabilities.includes(
                              "Validate Candidate Spelling"
                            )) ? (
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-lg-3 col-sm-3 col-6 pt-display"></div>
                              <div className="col-lg-3 col-sm-3 col-4">
                                <button
                                  className="correct-r"
                                  onClick={() => handleValidate(true)}
                                  style={
                                    result == true
                                      ? { borderBottom: "5px solid #00821d" }
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      "/Assets/images/correct-r7.png"
                                    }
                                    alt="correct"
                                  />{" "}
                                  Correct
                                </button>
                              </div>
                              <div className="col-lg-3 col-sm-3 col-4">
                                <button
                                  className="incorrect"
                                  onClick={() => handleValidate(false)}
                                  style={
                                    result == false
                                      ? { borderBottom: "5px solid #af0909" }
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      "/Assets/images/correct-r.png"
                                    }
                                    alt="incorrect"
                                  />{" "}
                                  Incorrect
                                </button>
                              </div>
                              {result !== null && (
                                <>
                                  <div className="col-lg-3 col-sm-3 col-4">
                                    <div className="right-edit">
                                      <button
                                        className="start-g"
                                        onClick={() => handleSubmit()}
                                      >
                                        Proceed
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        ) : null}
                        {/* </> */}
                        {/* // ) : (
                  //   <div className="wrapper">
                  //     <ComponentLoader />
                  //   </div>
                  // )} */}
                      </div>
                    </div>
                  </TranslatedComponent>
                </div>
              </>
            ) : (
              <div className="wrapper">
                <ComponentLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JuryValidate;
