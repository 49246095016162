import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../../component/Sideber/SidebarLeft";
import Header from "../../../../component/Header/Header";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { useSelector, useDispatch } from "react-redux";
import { GetOneShotDetails } from "../../../../Store/Action/oneShotAction";
import {
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
} from "../../../../Store/Types/oneShotTypes";
import Swal from "sweetalert2";
import FileUploader from "../../../../component/FileUploader/FileUploader";
import TranslatedComponent from "../../../../translator/TranslatedComponent";

import ComponentLoader from "../../../../component/ComponentLoader/ComponentLoader";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../../HelperFunctions/MsgTranslator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OneShotDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  // console.log(currentUserInfo)
  const {
    OneShot_Loading,
    OneShotDetails,
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
    OneShot_Get_Error_Message,
  } = useSelector((state) => state.OneShot);

  const [state, setState] = useState({});
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    dispatch(GetOneShotDetails({ orgId: currentUserInfo._id, oneshotId: id }));
  }, []);

  useEffect(() => {
    if (OneShotDetails && Object.keys(OneShotDetails).length > 0) {
      setState(OneShotDetails);
    }
  }, [OneShotDetails]);

  useEffect(() => {
    if (OneShot_Get_Error_Message) {
      const text = MsgTranslator(OneShot_Get_Error_Message, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: ONESHOT_ERROR_CLEAR });
      });
    }
  }, [OneShot_Get_Error_Message]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const convertSecondsToTime = (seconds) => {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${hours}h ${minutes}m ${remainingSeconds}s`;

    return formattedTime;
  };

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {state?.tournamentId ? (
            <TranslatedComponent>
              <Box
                sx={style}
                onClick={() => {
                  navigate(`/existing-participant/${id}`);
                }}
              >
                Add Existing Participants{" "}
                <i
                  className="fa -regular fa-arrow-right"
                  style={{ color: "#000000" }}
                ></i>
              </Box>
            </TranslatedComponent>
          ) : (
            <TranslatedComponent>
              <Box sx={style}>
                <Box
                  className="participants-mobel-option"
                  onClick={() => {
                    navigate(`/manual-add-participant/${id}`);
                  }}
                >
                  Manual Add{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                <Box
                  className="participants-mobel-option"
                  onClick={() => {
                    navigate(`/quick-mode-add-participant/${id}`);
                  }}
                >
                  Quick Add{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                <Box
                  className="participants-mobel-option"
                  onClick={() => {
                    navigate(`/existing-participant/${id}`);
                  }}
                >
                  Add Existing Participants{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                {/* <Box sx={{ border: 4, m: 2, p: 2 }} onClick={() => { navigate(``) }}>Import CSV or Excel <i className="fa -regular fa-arrow-right" style={{ color: "#000000" }}></i></Box> */}
                <FileUploader compId={id} />
              </Box>
            </TranslatedComponent>
          )}
        </Modal>
      </div>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard", language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                {
                  page: MsgTranslator("OneShot Details", language),
                  link: `/one-shot-details/${id}`,
                },
              ]}
            />
            {!OneShot_Loading ? (
              <>
                <div className="upcoming-title">
                  {state.tournamentId ? (
                    <h3>
                      <span>
                        <PageTitle
                          title={"<em>Sub Competition</em> Details"}
                          lang={language}
                        />
                      </span>
                    </h3>
                  ) : (
                    <h3>
                      <PageTitle
                        title={"<em>One Shot</em> Details"}
                        lang={language}
                      />
                    </h3>
                  )}
                </div>
                <TranslatedComponent>
                  <div className="candit-sectied-from">
                    {state.tournamentId ? (
                      <h4>Sub Competition</h4>
                    ) : (
                      <h4>One Shot Competition</h4>
                    )}
                    <div className="ft-create-section">
                      <div className="text-from-one">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Name
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">{state.name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Date
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">{state.date}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Time
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">{state.time}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-from-one">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Theme of The Words
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {state.themeOfWords}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Word Context
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {state.wordContext}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Word Level of Difficulty
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {state.difficulty}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-from-one">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Number of Participants
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {state.totalParticipants}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Number of Candidates to Qualify
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {state.qualifiedParticipants}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                Alloted Time Per Candidate
                              </label>
                              <div className="col-sm-7">
                                <p className="unser-piont">
                                  {state.allotedTime}sec (
                                  {convertSecondsToTime(state.allotedTime)})
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="buttaon-lane">
                      <div className="back-name">
                        <button
                          className="back-g"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Back
                        </button>
                      </div>
                      {currentUserInfo.role !== "Org" ||
                        (!state.isCompleted && (
                          <div className="right-edit">
                            <button
                              className="edit-g"
                              onClick={() => {
                                navigate(`/edit-one-shot-competition/${id}`);
                              }}
                            >
                              Edit
                            </button>
                            <button className="start-g" onClick={handleOpen}>
                              Add Participants
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </TranslatedComponent>
              </>
            ) : (
              <ComponentLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OneShotDetails;
