import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { AiCall } from "../../../HelperFunctions/AiCall";

import { EditWord } from "../../../Store/Action/wordAction";
// import {
//   WORD_ACTION_LOADER_START,
//   WORD_SUCCESS_CLEAR,
//   WORD_ERROR_CLEAR,
// } from "../../../Store/Types/wordTypes";

import { useSelector, useDispatch } from "react-redux";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import { getWordDetailsChange, wordDetailsChange } from "../../../HelperFunctions/Socket/socket";

const OrgView = ({
  curPlayer,
  current_round_Details,
  Words_Details,
  handleSubmit,
  setTimeTaken,
  setSubmitting,
  Word_Loading,
  round_Loading,
  stopTimer,
  SidebarLeft,
  Header,
  CountDownTimer,
  competitionId,
  Keyboard,
  handleInputChange,
  ComponentLoader,
  // setPageReload
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [AiBtn, setAiBtn] = useState({
    definition: false,
    illustrativeSentence: false,
  });

  getWordDetailsChange(({competitionId, wordDetailsState}) => {
    console.log(43,wordDetailsState)
    setState({
      ...state,
      ...wordDetailsState,
    });
  });

  useEffect(() => {
    if (Words_Details) {
      setState({
        ...state,
        ...Words_Details,
      });
    }
  }, [Words_Details]);

  const handleAIResponse = async (fieldname) => {
    setAiBtn({ ...AiBtn, [fieldname]: true });

    const prompt = `I want to generate only the field ${fieldname} for the word ${state.word}.Try to answer simple and short.The ${fieldname} should never reference a word different from ${state.word}, not even derivatives. It should be the word itself.`;
    let AiRes = await AiCall(prompt);
    setState({ ...state, [fieldname]: AiRes });
    dispatch(EditWord({ ...state, [fieldname]: AiRes, wordId: state._id })).then(()=>{
      // setPageReload(true);
      /* need socket call */
      console.log({
        wordDetailsApiCall: true,
        triggeredBy: "id",
        [fieldname]: AiRes,
        new: { ...state, [fieldname]: AiRes }
      });
      wordDetailsChange(current_round_Details.compId._id, { ...state, [fieldname]: AiRes });
    });
    setAiBtn({ ...AiBtn, [fieldname]: false });
  };

  return (
    <>
      {!round_Loading &&
      !Word_Loading &&
      Object.keys(current_round_Details).length > 0 &&
      Object.keys(curPlayer).length > 0 &&
      Object.keys(current_round_Details).length > 0 &&
      Object.keys(state).length > 0 ? (
        <div className="start-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                {Object.keys(current_round_Details).length > 0 &&
                  current_round_Details.compId &&
                  current_round_Details.compId.allotedTime && (
                    <CountDownTimer
                      competitionId={competitionId}
                      time={current_round_Details.compId.allotedTime}
                      stop={stopTimer}
                      timeSpent={setTimeTaken}
                      submit={setSubmitting}
                    />
                  )}
                <TranslatedComponent>
                  <div className="candit-sectied-from">
                    <h3>Candidate details</h3>
                    <ul className="candidat-pt-kk">
                      <li>
                        <span>Candidate Number :</span>{" "}
                        <p>{curPlayer.index || ""}</p>
                      </li>
                      <li>
                        <span>Candidate Name :</span>{" "}
                        <p>{curPlayer.firstName + " " + curPlayer.lastName} </p>
                      </li>
                    </ul>
                  </div>

                  <div className="candit-sectied-from">
                    <h1 style={{ textAlign: "center" }}>
                      {state?.word || "NA"}
                      {state?.spellingAlternatives &&
                      state?.spellingAlternatives !== "None" &&
                      state?.spellingAlternatives !== "N/A" &&
                      state?.spellingAlternatives !== "NA" &&
                      state?.spellingAlternatives !== "-" &&
                      state?.word !== state?.spellingAlternatives
                        ? ` / ${state?.spellingAlternatives}`
                        : ""}
                    </h1>

                    <ul className="candidat-pt-kk">
                      <li>
                        <span>Word Phonetics :</span>{" "}
                        <p>{state?.word_phonetics || "NA"}</p>
                      </li>
                      <li>
                        <span>Origin :</span> <p>{state?.origin || "NA"}</p>
                      </li>
                      <li>
                        <span>Nature :</span> <p>{state?.nature || "NA"}</p>
                      </li>
                      <li>
                        <span>Homonyms :</span> <p>{state?.homonyms || "NA"}</p>
                      </li>
                      <li>
                        <span>Definition :</span>{" "}
                        <p>{state?.definition || "NA"}</p>
                      </li>
                      <li>
                        <span>Illustrative Sentences :</span>{" "}
                        <p>{state?.illustrativeSentence || "N/A"}</p>
                        <div className="st-edit-fild">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleAIResponse("illustrativeSentence");
                            }}
                          >
                            {AiBtn.illustrativeSentence ? (
                              <div>
                                <CircularProgress
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                  }}
                                />
                              </div>
                            ) : (
                              <img
                                src={
                                  window.location.origin +
                                  `/./Assets/images/refrace.png`
                                }
                                alt="ai"
                              />
                            )}
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="search-box">
                    <div
                      className="input-group"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                      }}
                    >
                      <Keyboard
                        onInputChange={handleInputChange}
                        competitionId={competitionId}
                      />

                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={handleSubmit}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </TranslatedComponent>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <ComponentLoader />
        </div>
      )}
    </>
  );
};

export default OrgView;
