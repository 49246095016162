import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import { BASE_URL } from "../../../Config/Config";

import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";
import countryList from "react-select-country-list";

import {
  ManuallyJuryAdd,
  GetJuryDetails,
  UpdateJury,
} from "../../../Store/Action/juryAction";
import {
  JURY_ACTION_LOADER_START,
  JURY_SUCCESS_MSG_CLEAR,
  JURY_ERROR_MSG_CLEAR,
} from "../../../Store/Types/juryTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

import OutlinedInput from "@mui/material/OutlinedInput";
// import Select from "@mui/material/Select";
import { Select as MuiSelect } from "@mui/material";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const capabilitiesOptions = [
  "View Competition Details",
  "View Word",
  "View Word Phonetics",
  "View Other Word Details",
  "View Time",
  "Display Virtual Keyboard",
  "View Candidate Spelling Status",
  "Enter Candidate Spelling",
  "Spelling Enterted by Protractor",
  "View Candidate Profile And Details",
  "View list of Candidate",
  "Validate Candidate Spelling",
  "Go To Next Candidate",
  "Start New Round",
  "Round Overall Status",
];

const EditJuryMember = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { comp_id, jury_id } = params;

  const options = useMemo(() => countryList().getData(), []);

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser} = useSelector((state) => state.Auth);
  const {
    Jury_Loading,
    Jury_Action_Loader,
    Jury_SuccessMessage,
    Jury_ErrorMessage,
    Jury_Details,
    Jury_Get_Error_Message,
  } = useSelector((state) => state.Jury);

  /* get details every time page is open */
  useEffect(() => {
    dispatch(
      GetJuryDetails({
        orgId: currentUserInfo._id,
        oneShotId: comp_id,
        juryId: jury_id,
      })
    );
  }, []);

  const obj = {
    oneShotId: comp_id,
    orgId: currentUserInfo._id,
    role: "Jury",
    name: "",
    firstName: "",
    lastName: "",
    password: "",
    country: "",
    phone: "",
    email: "",
    JuryRole: null,
    capabilities: [],
  };

  const [state, setState] = useState(obj);

  /* handle multi select drop down checkbox---- capanbilities */
  let initialSelectedOption = [];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    if (Jury_Details && Jury_Details.capabilities) {
      setState({
        ...state,
        ...Jury_Details,
        JuryRole: Jury_Details.juryRole,
        name: Jury_Details.firstName + " " + Jury_Details.lastName,
        password: "",
      });
      setSelectedOptions(Jury_Details.capabilities);
    }
  }, [Jury_Details]);

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  const handleInputChange = (name, value) => {
    setState({ ...state, [name]: value });
    if (name === "JuryRole") {
      if (value === "Animator") {
        initialSelectedOption = [
          "View Competition Details",
          "View Word",
          "View Other Word Details",
          "View Time",
          "View Candidate Spelling Status",
          "Spelling Enterted by Protractor",
          "View Candidate Profile And Details",
          "View list of Candidate",
          "Go To Next Candidate",
          "Start New Round",
          "Round Overall Status",
        ];
        setSelectedOptions(initialSelectedOption);
      } else if (value === "Pronouncer") {
        initialSelectedOption = [
          "View Word Phonetics",
          "View Other Word Details",
          "View Time",
          "Round Overall Status",
        ];
        setSelectedOptions(initialSelectedOption);
      } else if (value === "Assistant Pronouncer") {
        initialSelectedOption = ["View Time", "Round Overall Status"];
        setSelectedOptions(initialSelectedOption);
      } else if (value === "Judge") {
        initialSelectedOption = [
          "View Word Phonetics",
          "View Time",
          "Spelling Enterted by Protractor",
          "View list of Candidate",
          "Validate Candidate Spelling",
          "Go To Next Candidate",
          "Start New Round",
          "Round Overall Status",
        ];
        setSelectedOptions(initialSelectedOption);
      } else if (value === "Assistant Judge") {
        const initialSelectedOption = [
          "View Word Phonetics",
          "View Time",
          "Spelling Enterted by Protractor",
          "View list of Candidate",
          "Go To Next Candidate",
          "Start New Round",
          "Round Overall Status",
        ];
        setSelectedOptions(initialSelectedOption);
      } else if (value === "Protractor") {
        const initialSelectedOption = [
          "Display Virtual Keyboard",
          "Enter Candidate Spelling",
          "Spelling Enterted by Protractor",
          "Validate Candidate Spelling",
          "Round Overall Status",
        ];
        setSelectedOptions(initialSelectedOption);
      }
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setState({ ...state, ["capabilities"]: selectedOptions });
    const data = {
      ...state,
      juryId: jury_id,
      capabilities: selectedOptions,
      // firstName: state.name.split("").includes(" ")
      //   ? state.name.split(" ")[0]
      //   : state.name,
      // lastName: state.name.split("").includes(" ")
      //   ? state.name.split(" ").slice(1).join(" ")
      //   : "",
    };

    dispatch({ type: JURY_ACTION_LOADER_START });
    dispatch(UpdateJury(data));
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Jury_SuccessMessage) {
      const text = MsgTranslator(Jury_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: JURY_SUCCESS_MSG_CLEAR });
      window.location.href.includes("/phase/")
      ? navigate(`/phase/all-jury/${comp_id}`)
      : navigate(`/all-jury/${comp_id}`);
    }
    if (Jury_ErrorMessage) {
      const text = MsgTranslator(Jury_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
    if (Jury_Get_Error_Message) {
      const text = MsgTranslator(Jury_Get_Error_Message,language)
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
  }, [Jury_SuccessMessage, Jury_ErrorMessage, Jury_Get_Error_Message]);
  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          {/* <button type="button" id="sidebarCollapse" className="btn btn-info">
            <i className="fas fa-align-left" />
          </button> */}

          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <em> </em>
                </span> */}
                  <PageTitle title={"<em>Jury</em> Members Edit"} lang={language} />
              </h3>
            </div>

            {!Jury_Loading ? (
              <form method="POST" onSubmit={handleSave}>
                <TranslatedComponent>
                <div className="candit-sectied-from">
                  <h2>Edit Jury</h2>
                  {/* role */}
                  <div className="select-rt">
                    <span>Role</span>
                    <div className="selet-dl">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        name="JuryRole"
                        value={state?.JuryRole}
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      >
                        <option value="">Select one role</option>
                        {/* <option value="Main Pronouncer">Main Pronouncer</option> */}
                        <option value="Animator">Animator</option>
                        <option value="Pronouncer">Pronouncer</option>
                        <option value="Assistant Pronouncer">
                          Assistant Pronouncer
                        </option>
                        <option value="Judge">Judge</option>
                        <option value="Assistant Judge">Assistant Judge</option>
                        <option value="Protractor">Protractor</option>
                      </select>
                    </div>
                  </div>
                  <div className="ft-create-section">
                    <div className="text-from-two">
                      <div className="row">
                        {/* first Name */}
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              First Name :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Name"
                                placeholder={MsgTranslator(
                                  "Name",
                                  language
                                )}
                                name="firstName"
                                value={state?.firstName || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>

                        {/* Last Name */}
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Last Name :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Name"
                                placeholder={MsgTranslator(
                                  "Name",
                                  language
                                )}
                                name="lastName"
                                value={state?.lastName || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>

                        {/* country */}
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Country :
                            </label>
                            <div className="col-sm-7">
                              <Select
                                options={options}
                                value={state.country}
                                onChange={(e) =>
                                  handleInputChange("country", e)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        {/* email */}
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Email :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Email"
                                placeholder={MsgTranslator(
                                  "Email",
                                  language
                                )}
                                name="email"
                                value={state.email}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                readOnly
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* phone */}
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Phone No :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Phone No"
                                placeholder={MsgTranslator(
                                  "Phone No",
                                  language
                                )}
                                name="phone"
                                value={state.phone}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>

                        {/* capabilities */}
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="capabilitiesCheckboxGroup"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Responsibility :
                            </label>
                            <div className="col-sm-7">
                              <FormControl sx={{ width: 230 }}>
                                <MuiSelect
                                  labelId="checkbox-dropdown-label"
                                  id="checkbox-dropdown"
                                  multiple
                                  value={selectedOptions}
                                  onChange={handleChange}
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                >
                                  {capabilitiesOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                      <Checkbox
                                        checked={
                                          selectedOptions &&
                                          selectedOptions.length > 0 &&
                                          selectedOptions.indexOf(option) > -1
                                        }
                                      />
                                      <ListItemText primary={option} />
                                    </MenuItem>
                                  ))}
                                </MuiSelect>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* password */}
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Password
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                // placeholder="Password"
                                placeholder={MsgTranslator(
                                  "Password",
                                  language
                                )}
                                name="password"
                                value={state.password}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                // required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="buttaon-lane">
                        <div className="back-name">
                          <button
                            className="back-g"
                            onClick={() => navigate(-1)}
                          >
                            Back
                          </button>
                        </div>
                        {/* <button className="edit-g">View Jury member</button> */}
                        <div className="right-edit">
                          <button
                            className="start-g"
                            type="submit"
                            // onClick={() => handleSave()}
                          >
                            {Jury_Action_Loader && (
                              <CircularProgress
                                size={24}
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "-10%",
                                  marginTop: "-12px",
                                  marginLeft: "-12px",
                                }}
                              />
                            )}
                            Save{" "}
                          </button>
                        </div>
                        {/* <button className="start-g">Add exiting member</button> */}
                      </div>
                    </div>
                  </div>
                </div>
                </TranslatedComponent>
              </form>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditJuryMember;
