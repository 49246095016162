import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Timer from "indra-timer";

import {
  btnStateChange,
  getBtnState,
  curentTimeChange,
  getCurrentTime,
  JoinRoom,
  StartSocketConnection,
} from "../../HelperFunctions/Socket/socket";

const CountDownTimer = ({ competitionId, time, stop, timeSpent, submit }) => {
  const { currentUserInfo } = useSelector((state) => state.Auth);

  const [timer, setTimer] = useState(new Timer());
  const [remainingTime, setRemainingTime] = useState(
    timer.calculateRemainingTime()
  );
  const [inputHours, setInputHours] = useState(0);
  const [inputMinutes, setInputMinutes] = useState(0);
  const [inputSeconds, setInputSeconds] = useState(time);
  const [intervalId, setIntervalId] = useState(null);
  const [ timeFinished, setTimeFinished] = useState(false);

  const [ButtonState, setButtonState] = useState("START");
  const [timerColor, setTimerColor] = useState("#017A0D");

  const [started, setstartrd] = useState(false);

  useEffect(() => {
    StartSocketConnection();
    if (currentUserInfo && Object.keys(currentUserInfo).length > 0) {
      JoinRoom(competitionId, currentUserInfo._id);
    }
  }, [currentUserInfo]);

  // getBtnState(({ competitionId, btnState }) => {
  //   console.log(btnState, ButtonState, btnState !== ButtonState);
  //   if (btnState !== ButtonState) {
  //     setButtonState(btnState); //btnState is boolean value
  //   }
  // });

  useEffect(() => {
    if (time && !started && currentUserInfo.role === "Org") {
      setstartrd(true);
      handleStartTimer();
    }
  }, [time]);

  const formatTimeAndUpdateState = (stringTime) => {
    setInputHours(stringTime.split(":")[0]);
    setInputMinutes(stringTime.split(":")[1]);
    setInputSeconds(stringTime.split(":")[2]);
  };
  const StartTimer = () => {
    setButtonState("PAUSE");
  };
  const handleStartTimer = () => {
    setButtonState("PAUSE");
    // Start the timer with user input
    timer.start(inputHours, inputMinutes, inputSeconds);
    // Log the remaining time every second
    const id = setInterval(() => {
      const timeRemaining = timer.calculateRemainingTime();
      setRemainingTime(timeRemaining);

      if (timeRemaining <= 0) {
        clearInterval(id);
        console.log("Timer completed!");
        setTimeFinished(true);
      }
    }, 100);

    setIntervalId(id);
  };

  const PauseTimer = () => {
    setButtonState("RESUME");
    handlePauseTimer();
  };
  const handlePauseTimer = () => {
    timer.pause();
    clearInterval(intervalId);
  };

  const ResumeTimer = () => {
    setButtonState("PAUSE");
    handleResumeTimer();
  };
  const handleResumeTimer = () => {
    timer.resume();

    // Log the remaining time every second
    const id = setInterval(() => {
      const timeRemaining = timer.calculateRemainingTime();
      setRemainingTime(timeRemaining);

      if (timeRemaining <= 0) {
        clearInterval(id);
        console.log("Timer completed!");
      }
    }, 100);

    setIntervalId(id);
  };

  const StopTimer = () => {
    handleStopTimer();
  };
  const handleStopTimer = async () => {
    // console.log("here to stop", time - remainingTime);
    timeSpent((prev) => prev + (time - remainingTime));
    submit(true);
    timer.pause();
    clearInterval(intervalId);
    // setRemainingTime(0);
    // setInputHours(0);
    // setInputMinutes(0);
    // setInputSeconds(0);
  };

  useEffect(() => {
    // console.log(remainingTime);
    if (currentUserInfo && currentUserInfo.role === "Org" ) {
      curentTimeChange(competitionId, remainingTime ? remainingTime : timeFinished ? 0 :time);
    }
    getCurrentTime(({ competitionId, time }) => {
      let currentTimeLeft = timer.formatTime(time);
      formatTimeAndUpdateState(currentTimeLeft);
    });
  }, [remainingTime, currentUserInfo]);

  useEffect(() => {
    if (stop === true) {
      // console.log("now stop val is true");
      // console.log({ remainingTime, time });
      handleStopTimer();
    } else if (stop === false) {
      // console.log("now stop val is false");
      curentTimeChange(competitionId, 0)
      setInputSeconds(0);
    }
  }, [stop]);

  function timeToSeconds(inputHours, inputMinutes, inputSeconds) {
    // Convert strings to integers
    const hoursInSeconds = parseInt(inputHours) * 3600;
    const minutesInSeconds = parseInt(inputMinutes) * 60;
    const seconds = parseInt(inputSeconds);

    // Return the total time in seconds
    return hoursInSeconds + minutesInSeconds + seconds;
  }

  useEffect(() => {
    if(timeFinished){
      setTimerColor("#E00707");
    }else{
      let timeRemaining = timeToSeconds(inputHours, inputMinutes, inputSeconds);
      let dynamicColor = timeRemaining
        ? timeRemaining / time <= 0.25
          ? "#E00707"
          : timeRemaining / time <= 0.6
            ? "#E8D000"
            : "#017A0D"
        : "#017A0D";
      setTimerColor(dynamicColor);
    }
  }, [inputHours, inputMinutes, inputSeconds, timeFinished])

  return (
    <div className="timeing-section">
      <div
        id="countdown"
        className="time-display"
        style={{
          backgroundColor: timerColor,
          // timer
          //   ? remainingTime / time <= 0.25
          //     ? "#E00707"
          //     : remainingTime / time <= 0.6
          //     ? "#E8D000"
          //     : "#017A0D"
          //   : "#017A0D",
        }}
      >
        <ul>
          <li>
            <span id="minute" className="numbers">
              {inputMinutes}
            </span>
          </li>
          <li>
            <span id="second" className="numbers">
              {inputSeconds}
            </span>
          </li>
        </ul>
      </div>
      {
        currentUserInfo.role === "Org" && (
          <>
            {ButtonState === "START" && (
              <button className="push-time" onClick={StartTimer}>
                <i className="fa-solid fa-play"></i>
              </button>
            )}
            {ButtonState === "PAUSE" && (
              <button className="push-time" onClick={PauseTimer}>
                <i className="fa-solid fa-pause"></i>
              </button>
            )}
            {ButtonState === "RESUME" && (
              <button className="push-time" onClick={ResumeTimer}>
                <i className="fa-solid fa-play"></i>
              </button>
            )}
          </>
        )
      }

    </div>
  );
};

export default CountDownTimer;